const HomeMainIcon6 = (props) => (
  <svg
    width="120"
    height="119"
    viewBox="0 0 120 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ddd_2541_9636)">
      <rect
        x="24"
        y="14"
        width="72"
        height="72"
        rx="36"
        fill="url(#paint0_linear_2541_9636)"
        fillOpacity="0.5"
        shapeRendering="crispEdges"
      />
      <rect
        x="24.43"
        y="14.43"
        width="71.14"
        height="71.14"
        rx="35.57"
        stroke="url(#paint1_linear_2541_9636)"
        strokeWidth="0.86"
        shapeRendering="crispEdges"
      />
      <path
        d="M72.9713 66.3586H72.5761C68.4261 66.3586 64.2762 66.3671 60.1263 66.3565C54.6276 66.3423 49.9661 63.1679 47.9887 58.0971C47.4027 56.5943 47.1458 55.0284 47.1565 53.4157C47.1664 51.8555 47.1586 50.2946 47.1586 48.7337C47.1586 48.5939 47.1586 48.4541 47.1586 48.259H40.0039C39.9706 47.2664 40.1508 46.3632 40.536 45.5203C41.7599 42.8455 43.8381 41.3711 46.7911 41.1873C47.0798 41.1696 47.1636 41.0901 47.1749 40.8014C47.3161 37.1566 50.3415 34.1533 53.9905 34.0929C56.8391 34.0454 59.6885 34.0795 62.5379 34.078C62.6046 34.078 62.6713 34.0887 62.7777 34.0979C60.4349 36.4315 58.1219 38.7353 55.8082 41.0398C55.8281 41.0717 55.8487 41.1043 55.8685 41.1362H64.2734C64.3883 40.497 64.4536 39.8492 64.6239 39.2305C65.4476 36.2463 68.1367 34.1447 71.2223 34.088C74.0476 34.0362 76.8728 34.027 79.6981 34C79.7684 34 79.8386 34.0057 79.9997 34.0135C79.8677 34.1547 79.7818 34.2526 79.6903 34.3441C77.5433 36.4946 75.3985 38.6466 73.2444 40.7893C73.0436 40.9894 72.9656 41.1887 72.9656 41.4711C72.9727 49.5936 72.9713 57.7153 72.9713 65.8378V66.3579V66.3586ZM63.9867 49.0388C62.9211 49.9874 62.9452 51.8073 64.0619 52.8737C65.1241 53.8883 66.9617 53.8592 67.794 52.8275C66.531 51.5703 65.2688 50.3145 63.9867 49.0395V49.0388Z"
        fill="#FF7B56"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_2541_9636"
        x="0.779999"
        y="0.239999"
        width="118.44"
        height="118.44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.969168" />
        <feGaussianBlur stdDeviation="1.18454" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2541_9636"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.72" />
        <feGaussianBlur stdDeviation="3.44" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2541_9636"
          result="effect2_dropShadow_2541_9636"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9.46" />
        <feGaussianBlur stdDeviation="11.61" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2541_9636"
          result="effect3_dropShadow_2541_9636"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_2541_9636"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2541_9636"
        x1="24"
        y1="14"
        x2="40.4452"
        y2="95.0734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F7F7F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2541_9636"
        x1="25.92"
        y1="14.8424"
        x2="41.6508"
        y2="95.5961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default HomeMainIcon6;
