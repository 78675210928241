const HomeMainIcon1 = (props) => (
  <svg
    width="120"
    height="119"
    viewBox="0 0 120 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ddd_2541_9639)">
      <rect
        x="24"
        y="14"
        width="72"
        height="72"
        rx="36"
        fill="url(#paint0_linear_2541_9639)"
        fillOpacity="0.5"
        shapeRendering="crispEdges"
      />
      <rect
        x="24.43"
        y="14.43"
        width="71.14"
        height="71.14"
        rx="35.57"
        stroke="url(#paint1_linear_2541_9639)"
        strokeWidth="0.86"
        shapeRendering="crispEdges"
      />
      <path
        d="M40 39.2213C40.31 38.5173 40.8423 38.2471 41.6136 38.2732C42.8493 38.3149 44.0861 38.2836 45.3229 38.2847C46.2706 38.2847 46.6421 38.5694 46.879 39.4748C47.1452 40.4927 47.4249 41.5066 47.6744 42.5287C47.7474 42.827 47.8789 42.9021 48.1774 42.9011C51.6926 42.8896 55.2068 42.8938 58.7219 42.8938H59.1582C59.0862 49.8111 62.4052 54.052 69.0765 55.7605C69.0296 56.5876 68.6549 56.9756 67.6832 57.1393C68.1695 59.2692 67.6874 60.7941 66.2084 61.8058C64.8568 62.7299 63.0742 62.7247 61.7351 61.7912C60.2687 60.7701 59.7855 59.2337 60.2802 57.13H57.3902C57.9632 59.1805 57.4726 60.8869 55.5898 61.9842C54.1578 62.8186 52.6643 62.7039 51.3377 61.7015C49.7941 60.5354 49.4466 58.9532 49.983 57.1227C49.1428 57.0987 48.7629 56.6157 48.5636 55.8377C47.3132 50.9699 46.0305 46.1094 44.777 41.2427C44.68 40.8651 44.5255 40.7608 44.1529 40.7733C43.3211 40.8025 42.4851 40.7472 41.6543 40.7921C40.8725 40.8349 40.3173 40.571 40.001 39.845V39.2203L40 39.2213ZM53.6934 59.9951C54.4845 59.991 55.1295 59.3422 55.1264 58.5547C55.1233 57.7672 54.4762 57.1143 53.6913 57.1081C52.8866 57.1018 52.2406 57.7599 52.2479 58.5756C52.2552 59.3735 52.8929 59.9993 53.6934 59.9951ZM63.9718 59.9951C64.7713 59.9993 65.409 59.3714 65.4173 58.5745C65.4257 57.7631 64.7723 57.1018 63.9707 57.1081C63.1827 57.1154 62.544 57.7631 62.5429 58.5558C62.5419 59.3516 63.1775 59.991 63.9718 59.9941V59.9951Z"
        fill="#FF7B56"
      />
      <path
        d="M70.8233 53.2925C65.7572 53.279 61.6554 49.1611 61.6857 44.1202C61.716 39.048 65.8115 34.9834 70.8776 35.0001C75.9323 35.0167 80.0204 39.1377 79.9996 44.1932C79.9787 49.2258 75.8634 53.3071 70.8233 53.2925ZM69.5959 45.3948C69.5959 45.9382 69.5865 46.419 69.598 46.8978C69.6106 47.4172 69.9341 47.8615 70.4038 48.0357C71.2199 48.3392 72.0507 47.7614 72.0883 46.8488C72.1081 46.3742 72.0914 45.8975 72.0914 45.4584C72.1624 45.4177 72.1728 45.4052 72.1843 45.4052C72.6269 45.4011 73.0694 45.4125 73.5109 45.3864C73.7259 45.3739 73.9544 45.3301 74.1444 45.2352C74.6548 44.9818 74.8917 44.4175 74.7644 43.849C74.6454 43.3161 74.1601 42.9166 73.5756 42.8988C73.0871 42.8842 72.5976 42.8957 72.0925 42.8957C72.0925 42.4045 72.1259 41.9726 72.081 41.5481C72.0497 41.2498 71.9693 40.9275 71.8128 40.6772C71.5341 40.2339 70.9694 40.0848 70.4622 40.2381C69.9727 40.3862 69.6137 40.842 69.5991 41.3719C69.5845 41.8725 69.5959 42.3732 69.5959 42.8947C69.073 42.8947 68.618 42.8874 68.1629 42.8968C67.4261 42.9114 66.8792 43.4412 66.875 44.1359C66.8698 44.8451 67.424 45.3812 68.1817 45.3917C68.6347 45.3979 69.0877 45.3927 69.5949 45.3927L69.5959 45.3948Z"
        fill="#FF7B56"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_2541_9639"
        x="0.779999"
        y="0.239999"
        width="118.44"
        height="118.44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.969168" />
        <feGaussianBlur stdDeviation="1.18454" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2541_9639"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.72" />
        <feGaussianBlur stdDeviation="3.44" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2541_9639"
          result="effect2_dropShadow_2541_9639"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9.46" />
        <feGaussianBlur stdDeviation="11.61" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2541_9639"
          result="effect3_dropShadow_2541_9639"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_2541_9639"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2541_9639"
        x1="24"
        y1="14"
        x2="40.4452"
        y2="95.0734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F7F7F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2541_9639"
        x1="25.92"
        y1="14.8424"
        x2="41.6508"
        y2="95.5961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default HomeMainIcon1;
