// core
import React from "react";
import { useTranslation } from "react-i18next";
// utils

// components
import ArrowSmDown from "./../../../components/icons/ArrowSmDown";
import AppButton from "../../../components/atoms/Button";
import ArrowUpRight from "../../../components/icons/ArrowUpRight";

// styles
import "./index.scss";

const HomeIcons = ({ generalData }) => {
  const { t } = useTranslation();

  const gotoAnchor = (anchor) => {
    let elem = document.getElementById(anchor);

    setTimeout(() => {
      if (elem) {
        window.scrollTo({ top: elem.offsetTop, behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className="home-icons">
      <div className="app__wrapper">
        <div className="home-icons__grid">
          {generalData.map((item) => (
            <div key={item.id} className="home-icons__card">
              <div className="home-icons__card-icon">
                <img src={item.icon} alt={item.title} />
              </div>
              <div className="home-icons__card-title">{item.title}</div>
              <div
                className="home-icons__card-text"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />

              {item.type === "anchor" && (
                <AppButton
                  className="app-button--fill-3 home-icons__card-btn"
                  to={"/reserves"}
                  onClick={() => gotoAnchor("contracts")}
                  append={<ArrowSmDown />}
                >
                  {t("actions.toSmartContract")}
                </AppButton>
              )}
              {item.type === "popup" && (
                <>
                  <AppButton
                    className="app-button--fill-3 home-icons__card-btn"
                    to={"/reserves"}
                    append={<ArrowUpRight />}
                  >
                    {t("actions.onReserves")}
                  </AppButton>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeIcons;
