import React from "react";

const UahSign = () => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.260778 11.3093V8.17391H12.852C13.04 7.96378 13.1838 7.74259 13.2833 7.51035C13.3939 7.26704 13.4492 7.00161 13.4492 6.71406C13.4492 5.9952 13.1616 5.4367 12.5865 5.03856C12.0115 4.62936 11.2484 4.42476 10.2972 4.42476C9.36824 4.42476 8.58302 4.61277 7.94157 4.98879C7.31118 5.36481 6.81904 5.86248 6.46514 6.48182L2.51691 5.13809C3.0367 3.7446 3.93805 2.60547 5.22095 1.72072C6.50384 0.824902 8.09641 0.376994 9.99863 0.376994C11.6465 0.376994 13.0455 0.664539 14.1957 1.23963C15.3569 1.80366 16.2417 2.56124 16.85 3.51235C17.4693 4.46346 17.779 5.50858 17.779 6.64771C17.779 6.91313 17.7568 7.17856 17.7126 7.44399C17.6794 7.69836 17.6241 7.94166 17.5467 8.17391H19.5374V11.3093H0.260778ZM9.63367 24.3981C8.04111 24.3981 6.6255 24.1272 5.38684 23.5853C4.14818 23.0323 3.17495 22.236 2.46714 21.1964C1.77039 20.1568 1.42202 18.8961 1.42202 17.4141C1.42202 17.2814 1.42755 17.1487 1.43861 17.016C1.44967 16.8722 1.46626 16.7339 1.48838 16.6012H0.260778V13.4659H19.5374V16.6012H5.88451C5.84028 16.7229 5.8071 16.8556 5.78498 16.9994C5.76286 17.1321 5.7518 17.2758 5.7518 17.4307C5.7518 18.3818 6.10017 19.1062 6.79692 19.6039C7.49367 20.1015 8.38948 20.3504 9.48437 20.3504C10.524 20.3504 11.4198 20.1181 12.1718 19.6536C12.9349 19.1891 13.5211 18.5864 13.9303 17.8454L18.0776 19.4214C17.6131 20.4278 16.9716 21.307 16.1532 22.0591C15.3459 22.8 14.3892 23.3751 13.2833 23.7843C12.1884 24.1935 10.9719 24.3981 9.63367 24.3981Z"
      fill="#376FE5"
    />
  </svg>
);

export default UahSign;
