const HomeMainIcon3 = (props) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ddd_2308_33991)">
      <rect
        x="24"
        y="14"
        width="72"
        height="72.6012"
        rx="36"
        fill="url(#paint0_linear_2308_33991)"
        fillOpacity="0.5"
        shapeRendering="crispEdges"
      />
      <rect
        x="24.43"
        y="14.43"
        width="71.14"
        height="71.7411"
        rx="35.57"
        stroke="url(#paint1_linear_2308_33991)"
        strokeWidth="0.86"
        shapeRendering="crispEdges"
      />
      <g clipPath="url(#clip0_2308_33991)">
        <path
          d="M74.7831 54.2603C74.4665 54.4785 74.0969 54.6072 73.7133 54.633C73.3296 54.6587 72.9462 54.5806 72.6032 54.4067C71.7982 53.9519 71.3643 52.8855 71.3643 51.4323V46.9786C71.3643 44.8406 70.5175 43.3194 69.1009 42.9064C66.7067 42.206 64.919 45.1385 64.229 46.2415L59.9948 53.0946V44.7308C59.9477 42.8019 59.3204 41.6466 58.1338 41.3016C57.3497 41.0716 56.1735 41.1657 55.0288 42.9117L45.5619 58.1077C44.3023 55.6978 43.6492 53.0172 43.6592 50.298C43.6592 41.1396 50.9775 33.6905 59.9948 33.6905C69.012 33.6905 76.3565 41.1396 76.3565 50.298V50.345C76.3565 50.345 76.3565 50.3764 76.3565 50.3921C76.4454 52.1641 75.8704 53.5755 74.7883 54.2603H74.7831ZM80.0105 50.3032V50.2143C79.9373 39.0538 70.988 30 59.9948 30C49.0016 30 40 39.1061 40 50.298C40 61.4898 48.9702 70.6012 59.9948 70.6012C65.0511 70.6009 69.9151 68.6628 73.586 65.1856C73.9423 64.8499 74.152 64.3873 74.1696 63.898C74.1872 63.4088 74.0113 62.9323 73.6801 62.5719C73.5216 62.3965 73.3299 62.2543 73.1162 62.1533C72.9025 62.0523 72.6709 61.9945 72.4348 61.9833C72.1987 61.9722 71.9627 62.0078 71.7404 62.0882C71.5182 62.1685 71.3139 62.2921 71.1396 62.4516C69.5591 63.9508 67.692 65.1152 65.6504 65.8748C63.6088 66.6344 61.4347 66.9736 59.2587 66.8721C57.0827 66.7705 54.9496 66.2303 52.9876 65.2838C51.0256 64.3374 49.275 63.0042 47.8411 61.3643L56.3617 47.6686V53.9885C56.3617 57.0256 57.5379 58.0084 58.5259 58.2959C59.5139 58.5834 61.0246 58.3847 62.6085 55.8129L67.3131 48.1966C67.4595 47.9509 67.6006 47.7418 67.7261 47.5588V51.4323C67.7261 54.2708 68.8657 56.5395 70.8625 57.6581C71.7705 58.1483 72.7937 58.3851 73.8247 58.3437C74.8557 58.3023 75.8566 57.9843 76.7224 57.4229C78.9179 55.9958 80.115 53.4135 79.9895 50.3032H80.0105Z"
          fill="#FF7B56"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddd_2308_33991"
        x="0.779999"
        y="0.239999"
        width="118.44"
        height="119.042"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.969168" />
        <feGaussianBlur stdDeviation="1.18454" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2308_33991"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.72" />
        <feGaussianBlur stdDeviation="3.44" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2308_33991"
          result="effect2_dropShadow_2308_33991"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9.46" />
        <feGaussianBlur stdDeviation="11.61" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2308_33991"
          result="effect3_dropShadow_2308_33991"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_2308_33991"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2308_33991"
        x1="24"
        y1="14"
        x2="40.7099"
        y2="95.6961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F7F7F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2308_33991"
        x1="25.92"
        y1="14.8494"
        x2="41.9048"
        y2="96.2275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2308_33991">
        <rect
          width="40"
          height="40.6012"
          fill="white"
          transform="translate(40 30)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HomeMainIcon3;
