// core
import React, { useEffect, useState } from "react";

// services

// components
import Close from "../../icons/Close";

// styles
import "./index.scss";

const ExchangeModal = ({ isActive, data, onClose }) => {
  const [isLocalActive, setLocalActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        // @ts-ignore
        document.querySelector("body").classList.add("body--fixed");
        setLocalActive(true);
      }, 100);
    }
  }, [isActive]);

  const onCloseHandler = () => {
    setLocalActive(false);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector("body").classList.remove("body--fixed");
    }, 300);
    onClose();
  };

  return (
    <div className={"app-modal" + (isLocalActive ? " app-modal--active" : "")}>
      <div className="app-modal__overlay" onClick={onCloseHandler} />
      <div className="app-modal__wrapper exchange-modal">
        <div className="app-modal__close" onClick={onCloseHandler}>
          <Close />
        </div>
        <div className="app-modal__content-scroll">
          <div className="exchange-modal__content">
            <div className="exchange-modal__title">{data.popup_title}</div>
            <div className="exchange-modal__grid">
              <div className="exchange-modal__card">
                <div className="exchange-modal__card-icon">
                  <img src={data.popup_left_image} alt="" />
                </div>
                <div className="exchange-modal__card-title">
                  {data.popup_left_title}
                </div>
                <div
                  className="exchange-modal__card-text"
                  dangerouslySetInnerHTML={{
                    __html: data.popup_left_description,
                  }}
                />
              </div>
              <div className="exchange-modal__card">
                <div className="exchange-modal__card-icon">
                  <img src={data.popup_right_image} alt="" />
                </div>
                <div className="exchange-modal__card-title">
                  {data.popup_right_title}
                </div>
                <div
                  className="exchange-modal__card-text"
                  dangerouslySetInnerHTML={{
                    __html: data.popup_right_description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeModal;
