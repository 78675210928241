// core
import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link as RouterLink } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useTranslation } from "react-i18next";
// utils

// components

// styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./index.scss";

const HomeNews = ({ data }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const getNavigationStatus = () => {
    let status = false;
    let slidesCount = data.length;
    if (
      (width > 1024 && slidesCount > 4) ||
      (width > 768 && width < 1023 && slidesCount > 3) ||
      (width < 767 && slidesCount > 2)
    ) {
      status = true;
    }

    return status;
  };

  return (
    <div className="home-news">
      <div className="app__wrapper">
        <div className="home-news__title">{t("home.sliderTitle")}</div>
        <div className="home-news__content">
          <Swiper
            spaceBetween={24}
            slidesPerView={"auto"}
            pagination={{ clickable: true }}
            navigation={getNavigationStatus()}
            loop={true}
            modules={[Navigation, Pagination]}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <RouterLink
                  to={`/article/${item.slug}`}
                  key={item.id}
                  className="home-news__card"
                >
                  <div className="home-news__card-img">
                    <img src={item.image} alt={item.title} />
                  </div>

                  <div className="home-news__card-title">{item.title}</div>
                  <div className="home-news__card-text">
                    {item.short_description}
                  </div>
                </RouterLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeNews;
