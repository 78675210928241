// core
import React from "react";
import { useTranslation } from "react-i18next";

// store
// components
import QuestionBox from "../../../components/icons/QuestionBox";
import HomeSecuritySignImg from "../../../assets//images/home-security-sign.png";

// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";

const HomeSecurity = ({ data }) => {
  const { t, i18n } = useTranslation();
  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  const onSendHandler = () => {
    window.open(`https://whiteex.com/${localization}/#mainForm`, "_blank");
  };
  return (
    <div className="home-security">
      <div className="app__wrapper">
        <div className="home-security__container">
          <div className="home-security__grid">
            <div className="home-security__bg-main" />
            <div className="home-security__card home-security__card--left">
              <div className="home-security__bg-1" />
              <div
                className="home-security__card-head"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <QuestionBox />
                <div
                  className="home-security__card-title"
                  dangerouslySetInnerHTML={{
                    __html: data.left_title,
                  }}
                ></div>
              </div>
              <div
                className="home-security__card-body"
                data-aos="fade-up"
                data-aos-delay="400"
                dangerouslySetInnerHTML={{
                  __html: data.left_description,
                }}
              />
            </div>
            <div className="home-security__card home-security__card--right">
              <div className="home-security__bg-2" />
              <div className="home-security__bg-3" />
              <div
                className="home-security__card-head"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <QuestionBox />
                <div
                  className="home-security__card-title"
                  dangerouslySetInnerHTML={{
                    __html: data.right_title,
                  }}
                ></div>
              </div>
              <div
                className="home-security__card-body"
                data-aos="fade-up"
                data-aos-delay="1000"
                dangerouslySetInnerHTML={{
                  __html: data.right_description,
                }}
              />
            </div>
          </div>
          <div className="home-security__footer">
            <div className="home-security__circle">
              <img src={HomeSecuritySignImg} className="home-security__img" />
            </div>

            <div
              className="home-security__text"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            ></div>
            <div className="home-security__actions">
              <AppButton
                className="app-button--fill-3 home-security__btn"
                onClick={() => {
                  onSendHandler();
                }}
              >
                {t("actions.buyUahg")}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSecurity;
