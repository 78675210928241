// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import {
  getWeldBlocks,
  getWeldContent,
} from "../../store/modules/weld/services";
import {
  selectWeldBlocks,
  selectWeldContent,
} from "../../store/modules/weld/selectors";
// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import WeldReferral from "./WeldReferral";
import WeldCTA from "./WeldCTA";
import WeldAction from "./WeldAction";
import WeldCashback from "./WeldCashback";
import WeldRate from "./WeldRate";
import WeldBanner from "./WeldBanner";

// styles
import "./index.scss";

import AOS from "aos";

const WellCard = () => {
  // store
  const dispatch = useDispatch();
  const weldBlocks = useSelector(selectWeldBlocks);
  const weldContent = useSelector(selectWeldContent);

  useEffect(() => {
    dispatch(getWeldBlocks());
    dispatch(getWeldContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (weldBlocks.isSuccess && weldContent.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weldBlocks.isSuccess, weldContent.isSuccess]);

  console.log("weldContent", weldContent.data);

  // @ts-ignore
  return (
    <div className="weld">
      {weldBlocks.isSuccess && weldContent.isSuccess ? (
        <>
          <WeldBanner data={weldBlocks.data.app} />
          <WeldAction data={weldBlocks.data.giveaway} />
          <WeldRate data={weldBlocks.data.rate} />
          <WeldCashback data={weldBlocks.data.cashback} />
          {weldContent.data.referral_cards.length > 0 && (
            <WeldReferral
              data={weldBlocks.data.referral}
              referrals={weldContent.data.referral_cards}
            />
          )}

          <WeldCTA data={weldBlocks.data.app_install} />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default WellCard;
