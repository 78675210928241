// core
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { Link as RouterLink } from "react-router-dom";

// utils

// components
import ArrowUpRight from "./../../../components/icons/ArrowUpRight";
import HowModal from "../../../components/features/HowModal";
import Document from "../../../components/icons/Document";
import HomeAboutBgImg from "../../../assets/images/home-about-bg.png";
import HomeAboutBgMobImg from "../../../assets/images/home-about-bg-mob.png";

// styles
import "./index.scss";

const HomeAbout = ({ data, variables }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isActiveModal, setIsActiveModal] = useState(false);

  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };

  return (
    <div className="home-about" id="about">
      <div className="app__wrapper">
        <div className="home-about__grid">
          <div
            className="home-about__box"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2 className="home-about__box-title">{data.title}</h2>

            <div
              className="home-about__box-text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <div className="home-about__box-actions">
              <span
                className="home-about__box-link"
                title={t("aboutToken.whitepaper")}
                onClick={() => {
                  openModalHandler();
                }}
              >
                {t("actions.scheme")}
              </span>
              <RouterLink
                className="home-about__box-link"
                title={t("aboutToken.whitepaper")}
                to={"/reserves"}
              >
                {t("actions.onReserves")}
                <ArrowUpRight />
              </RouterLink>
            </div>
            <div className="home-about__box-actions">
              {variables.whitepaper_about &&
                variables.whitepaper_about.value && (
                  <a
                    href={variables.whitepaper_about.value}
                    className="home-about__box-link"
                    title={t("aboutToken.whitepaper")}
                    target="_blank"
                  >
                    <Document />
                    {t("aboutToken.whitepaper")}
                    <ArrowUpRight />
                  </a>
                )}

              {variables.documents && variables.documents.value && (
                <a
                  href={variables.documents.value}
                  className="home-about__box-link"
                  title={t("aboutToken.license")}
                  target="_blank"
                >
                  <Document />
                  {t("aboutToken.license")}
                  <ArrowUpRight />
                </a>
              )}
            </div>
          </div>
          <div className="home-about__globe">
            <img
              src={width > 480 ? HomeAboutBgImg : HomeAboutBgMobImg}
              className="home-about__bg"
            />

            {variables.token_number && (
              <div className="home-about__card home-about__card--1">
                <div className="home-about__card-title">
                  {variables.token_number.value}
                </div>

                <div className="home-about__card-text">
                  {t("token.numberText")}
                </div>
              </div>
            )}
            {variables.token_partners && (
              <div className="home-about__card home-about__card--2">
                <div className="home-about__card-title">
                  {variables.token_partners.value}
                </div>

                <div className="home-about__card-text">
                  {t("token.partnerText")}
                </div>
              </div>
            )}
            {variables.token_users && (
              <div className="home-about__card home-about__card--3">
                <div className="home-about__card-title">
                  {variables.token_users.value}
                </div>
                <div className="home-about__card-text">
                  {t("token.userText")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isActiveModal &&
        ReactDOM.createPortal(
          <HowModal
            isActive={isActiveModal}
            data={data.popup}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeAbout;
