// core

// components
import Header from "../../components/atoms/Header";
import Footer from "../../components/atoms/Footer";

// style
import "./index.scss";

const HomeLayout = ({ children }) => {
  return (
    <div className="app-layout app-layout--home">
      <div className="app-layout__top">
        <Header />
      </div>
      <div className="app-layout__content">{children}</div>
      <div className="app-layout__bottom">
        <Footer />
      </div>
    </div>
  );
};

export default HomeLayout;
