const HomeMainIcon2 = (props) => (
  <svg
    width="120"
    height="119"
    viewBox="0 0 120 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ddd_2308_33977)">
      <rect
        x="24"
        y="14"
        width="72"
        height="72"
        rx="36"
        fill="url(#paint0_linear_2308_33977)"
        fillOpacity="0.5"
        shapeRendering="crispEdges"
      />
      <rect
        x="24.43"
        y="14.43"
        width="71.14"
        height="71.14"
        rx="35.57"
        stroke="url(#paint1_linear_2308_33977)"
        strokeWidth="0.86"
        shapeRendering="crispEdges"
      />
      <path
        d="M75.9997 56.0493C75.8025 56.6514 75.5167 57.1895 74.9057 57.4166C74.6275 57.5198 74.3195 57.582 74.0247 57.5829C70.5771 57.5942 67.13 57.5928 63.6824 57.5886C62.4242 57.5872 61.6925 56.8116 61.6906 55.4952C61.6893 54.2476 61.6888 52.9994 61.6906 51.7518C61.6925 50.4429 62.4342 49.674 63.6969 49.6735C67.0504 49.6721 70.4043 49.6735 73.7578 49.6735C75.0816 49.6735 75.5045 49.9675 75.9992 51.2316V56.0498L75.9997 56.0493ZM64.8538 53.6237C64.8538 54.3503 64.9596 54.6532 65.3354 54.8191C65.7994 55.024 66.2965 55.0325 66.7555 54.8181C67.2611 54.5816 67.248 54.0789 67.2498 53.5903C67.2512 53.1474 67.1621 52.7389 66.7469 52.5627C66.287 52.3676 65.7886 52.3624 65.3259 52.581C64.8732 52.795 64.8307 53.2331 64.8538 53.6237Z"
        fill="#FF7B56"
      />
      <path
        d="M74.142 48.3717C73.9859 48.3717 73.8629 48.3717 73.7404 48.3717C70.3661 48.3717 66.9913 48.3665 63.617 48.3745C62.3018 48.3778 61.2987 48.9734 60.7447 50.2295C60.5516 50.6672 60.4652 51.1874 60.4512 51.6736C60.4123 52.9962 60.4331 54.3206 60.439 55.6446C60.4471 57.4953 61.7813 58.8923 63.5645 58.9018C65.5536 58.9126 67.5431 58.9041 69.5321 58.9046C70.9486 58.9046 72.365 58.9046 73.7815 58.9046H74.1329C74.1393 59.0469 74.1483 59.1529 74.1483 59.2584C74.1492 60.81 74.1515 62.3615 74.1483 63.9131C74.1456 65.2361 73.4179 65.9989 72.1547 65.9989C63.4374 66.0003 54.7201 66.0003 46.0028 65.9989C44.7397 65.9989 44.0007 65.2286 44.0007 63.9173C43.9998 57.0925 43.9998 50.2677 44.0007 43.4428C44.0007 42.1773 44.7496 41.397 45.9694 41.3966C54.7179 41.3952 63.4664 41.3952 72.2149 41.3966C73.336 41.3966 74.1085 42.0915 74.132 43.211C74.1673 44.9129 74.1411 46.6166 74.1411 48.3708L74.142 48.3717Z"
        fill="#FF7B56"
      />
      <path
        d="M55.7889 40.0261C56.7495 39.7509 57.7097 39.4739 58.6712 39.2006C61.6294 38.3595 64.5881 37.5195 67.5472 36.6798C68.738 36.342 69.6326 36.8636 69.9673 38.0976C70.1423 38.7421 70.306 39.39 70.4828 40.0666H55.7871C55.788 40.0529 55.7889 40.0397 55.7894 40.0261H55.7889Z"
        fill="#FF7B56"
      />
      <path
        d="M64.2311 36.4535C63.4654 36.6735 62.7373 36.8846 62.0078 37.0919C58.5887 38.0654 55.1737 39.0553 51.7456 39.9924C51.1572 40.1531 50.5118 40.0867 49.8927 40.1248C49.885 40.0966 49.8768 40.0683 49.8691 40.04C49.941 39.9943 50.0098 39.9425 50.0849 39.9034C53.7269 38.0211 57.3685 36.1383 61.0119 34.2602C62.0928 33.7033 63.0837 34.0548 63.6251 35.1752C63.8245 35.588 64.0181 36.004 64.2306 36.453L64.2311 36.4535Z"
        fill="#FF7B56"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_2308_33977"
        x="0.779999"
        y="0.239999"
        width="118.44"
        height="118.44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.969168" />
        <feGaussianBlur stdDeviation="1.18454" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2308_33977"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.72" />
        <feGaussianBlur stdDeviation="3.44" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2308_33977"
          result="effect2_dropShadow_2308_33977"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9.46" />
        <feGaussianBlur stdDeviation="11.61" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2308_33977"
          result="effect3_dropShadow_2308_33977"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_2308_33977"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2308_33977"
        x1="24"
        y1="14"
        x2="40.4452"
        y2="95.0734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F7F7F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2308_33977"
        x1="25.92"
        y1="14.8424"
        x2="41.6508"
        y2="95.5961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default HomeMainIcon2;
