const DocumentShield = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9V4C18 3.44772 17.5523 3 17 3H8.91421C8.649 3 8.39464 3.10536 8.20711 3.29289L4.29289 7.20711C4.10536 7.39464 4 7.649 4 7.91421V20C4 20.5523 4.44772 21 5 21H11M9 3V7C9 7.55228 8.55228 8 8 8H4M15.0005 21C13.48 19.9724 12.2328 18.6042 11.3629 17.0096C10.4931 15.4149 10.0258 13.6401 10 11.833L15.0005 10L20 11.833C19.9743 13.64 19.5071 15.4147 18.6374 17.0093C17.7678 18.6039 16.5208 19.9722 15.0005 21Z"
      stroke="#0071F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 15.5L14.5 16.5L17 14"
      stroke="#0071F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentShield;
