// core
import React from "react";

// store
// components
import HomeReliabilityImg from "./../../../assets/images/home-reliability.png";

// styles
import "./index.scss";

const HomeReliability = ({ data }) => {
  return (
    <div className="home-reliability">
      <div className="home-reliability__bg-1" />
      <div className="home-reliability__bg-2" />

      <div className="app__wrapper">
        <div className="home-reliability__title">{data.title}</div>
        <div className="home-reliability__grid">
          <div
            className="home-reliability__card home-reliability__card--left"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <img src={data.left_image} className="home-reliability__card-img" />
            <div
              className="home-reliability__card-text"
              dangerouslySetInnerHTML={{
                __html: data.left_title,
              }}
            />
            <div
              className="home-reliability__card-text"
              dangerouslySetInnerHTML={{
                __html: data.left_description,
              }}
            />
          </div>
          <img
            src={HomeReliabilityImg}
            className="home-reliability__img"
            data-aos="fade-up"
            data-aos-delay="100"
          />
          <div
            className="home-reliability__card home-reliability__card--right"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <img
              src={data.right_image}
              className="home-reliability__card-img"
            />
            <div
              className="home-reliability__card-text"
              dangerouslySetInnerHTML={{
                __html: data.right_title,
              }}
            />
            <div
              className="home-reliability__card-text"
              dangerouslySetInnerHTML={{
                __html: data.right_description,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeReliability;
