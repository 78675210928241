// core
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// utils
import ScrollToTop from "./utils/scroll-to-top";

// layouts
import MainLayout from "./layouts/MainLayout";
import HomeLayout from "./layouts/HomeLayout";

// modules
import Home from "./modules/Home";
import Reserves from "./modules/Reserves";
import Team from "./modules/Team";
import Article from "./modules/Article";
import News from "./modules/News";
import Ambassador from "./modules/Ambassador";
import Weld from "./modules/Weld";
import Page404 from "./modules/Page404";

//styles
import "aos/dist/aos.css";

//data
import { DEFAULT_LOCALE } from "./locales/data";
import useSetUrlLang from "./hooks/useSetUrlLang";

function App() {
  const { i18n } = useTranslation();

  const currentLocale =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  useSetUrlLang(currentLocale);

  return (
    <div className="app">
      <ScrollToTop />
      <Routes>
        <Route
          path={`/${currentLocale === DEFAULT_LOCALE ? "" : currentLocale}`}
          element={
            <HomeLayout>
              <Home />
            </HomeLayout>
          }
        />

        <Route
          path={`/:lang?/reserves`}
          element={
            <MainLayout>
              <Reserves />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/team`}
          element={
            <MainLayout>
              <Team />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/article/:slug`}
          element={
            <MainLayout>
              <Article />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/news`}
          element={
            <MainLayout>
              <News />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/ambassador`}
          element={
            <MainLayout>
              <Ambassador />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/weld-card`}
          element={
            <MainLayout>
              <Weld />
            </MainLayout>
          }
        />

        <Route
          path={`/:lang?/page-not-found`}
          element={
            <MainLayout>
              <Page404 />
            </MainLayout>
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={`${
                currentLocale === DEFAULT_LOCALE ? "" : "/" + currentLocale
              }/page-not-found`}
            />
          }
        />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
