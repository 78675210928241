const HomeMainBg = (props) => (
  <svg
    width="784"
    height="784"
    viewBox="0 0 784 784"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2308_33949)">
      <g opacity="0.45">
        <path
          d="M510.574 250.833L510.753 251.762M510.753 251.762C419.958 268.938 292.853 345.985 227.421 423.517C194.317 462.737 181.964 496.587 193.522 516.388C203.764 533.93 231.857 539.338 272.637 531.624C363.432 514.448 490.537 437.4 555.969 359.868C589.073 320.649 601.426 286.799 589.868 266.998C579.634 249.458 551.533 244.047 510.753 251.762ZM217.395 534.442C205.437 531.431 196.766 525.696 191.888 517.331C179.899 496.799 192.325 462.16 225.972 422.286C291.642 344.477 419.241 267.141 510.398 249.896C552.018 242.023 580.82 247.754 591.491 266.035C603.479 286.567 591.053 321.206 557.406 361.08C491.736 438.889 364.138 516.225 272.98 533.469C250.393 537.745 231.578 538.006 217.389 534.432L217.395 534.442Z"
          stroke="url(#paint0_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M510.574 250.833L510.753 251.762M510.753 251.762C419.958 268.938 292.853 345.985 227.421 423.517C194.317 462.737 181.964 496.587 193.522 516.388C203.764 533.93 231.857 539.338 272.637 531.624C363.432 514.448 490.537 437.4 555.969 359.868C589.073 320.649 601.426 286.799 589.868 266.998C579.634 249.458 551.533 244.047 510.753 251.762ZM217.395 534.442C205.437 531.431 196.766 525.696 191.888 517.331C179.899 496.799 192.325 462.16 225.972 422.286C291.642 344.477 419.241 267.141 510.398 249.896C552.018 242.023 580.82 247.754 591.491 266.035C603.479 286.567 591.053 321.206 557.406 361.08C491.736 438.889 364.138 516.225 272.98 533.469C250.393 537.745 231.578 538.006 217.389 534.432L217.395 534.442Z"
          stroke="url(#paint1_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M510.574 250.833L510.753 251.762M510.753 251.762C419.958 268.938 292.853 345.985 227.421 423.517C194.317 462.737 181.964 496.587 193.522 516.388C203.764 533.93 231.857 539.338 272.637 531.624C363.432 514.448 490.537 437.4 555.969 359.868C589.073 320.649 601.426 286.799 589.868 266.998C579.634 249.458 551.533 244.047 510.753 251.762ZM217.395 534.442C205.437 531.431 196.766 525.696 191.888 517.331C179.899 496.799 192.325 462.16 225.972 422.286C291.642 344.477 419.241 267.141 510.398 249.896C552.018 242.023 580.82 247.754 591.491 266.035C603.479 286.567 591.053 321.206 557.406 361.08C491.736 438.889 364.138 516.225 272.98 533.469C250.393 537.745 231.578 538.006 217.389 534.432L217.395 534.442Z"
          stroke="url(#paint2_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M510.574 250.833L510.753 251.762M510.753 251.762C419.958 268.938 292.853 345.985 227.421 423.517C194.317 462.737 181.964 496.587 193.522 516.388C203.764 533.93 231.857 539.338 272.637 531.624C363.432 514.448 490.537 437.4 555.969 359.868C589.073 320.649 601.426 286.799 589.868 266.998C579.634 249.458 551.533 244.047 510.753 251.762ZM217.395 534.442C205.437 531.431 196.766 525.696 191.888 517.331C179.899 496.799 192.325 462.16 225.972 422.286C291.642 344.477 419.241 267.141 510.398 249.896C552.018 242.023 580.82 247.754 591.491 266.035C603.479 286.567 591.053 321.206 557.406 361.08C491.736 438.889 364.138 516.225 272.98 533.469C250.393 537.745 231.578 538.006 217.389 534.432L217.395 534.442Z"
          stroke="url(#paint3_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M208.203 534.678C194.228 531.159 184.381 524.412 179.374 514.618C167.655 491.714 183.45 454.523 222.704 412.568C296.754 333.428 432.65 259.368 525.633 247.473C566.477 242.248 594.312 249.814 604.006 268.766C615.725 291.671 599.93 328.862 560.676 370.816C486.625 449.956 350.729 524.016 257.746 535.912C237.999 538.437 221.285 537.973 208.203 534.678ZM574.728 250.52C561.9 247.29 545.423 246.852 525.868 249.349C433.251 261.194 297.868 334.988 224.077 413.865C185.4 455.199 169.717 491.61 181.054 513.762C190.359 531.952 217.512 539.152 257.502 534.041C350.119 522.196 485.504 448.395 559.292 369.525C597.969 328.191 613.652 291.78 602.316 269.628C597.556 260.325 588.14 253.906 574.718 250.526L574.728 250.52Z"
          stroke="url(#paint4_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.203 534.678C194.228 531.159 184.381 524.412 179.374 514.618C167.655 491.714 183.45 454.523 222.704 412.568C296.754 333.428 432.65 259.368 525.633 247.473C566.477 242.248 594.312 249.814 604.006 268.766C615.725 291.671 599.93 328.862 560.676 370.816C486.625 449.956 350.729 524.016 257.746 535.912C237.999 538.437 221.285 537.973 208.203 534.678ZM574.728 250.52C561.9 247.29 545.423 246.852 525.868 249.349C433.251 261.194 297.868 334.988 224.077 413.865C185.4 455.199 169.717 491.61 181.054 513.762C190.359 531.952 217.512 539.152 257.502 534.041C350.119 522.196 485.504 448.395 559.292 369.525C597.969 328.191 613.652 291.78 602.316 269.628C597.556 260.325 588.14 253.906 574.718 250.526L574.728 250.52Z"
          stroke="url(#paint5_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.203 534.678C194.228 531.159 184.381 524.412 179.374 514.618C167.655 491.714 183.45 454.523 222.704 412.568C296.754 333.428 432.65 259.368 525.633 247.473C566.477 242.248 594.312 249.814 604.006 268.766C615.725 291.671 599.93 328.862 560.676 370.816C486.625 449.956 350.729 524.016 257.746 535.912C237.999 538.437 221.285 537.973 208.203 534.678ZM574.728 250.52C561.9 247.29 545.423 246.852 525.868 249.349C433.251 261.194 297.868 334.988 224.077 413.865C185.4 455.199 169.717 491.61 181.054 513.762C190.359 531.952 217.512 539.152 257.502 534.041C350.119 522.196 485.504 448.395 559.292 369.525C597.969 328.191 613.652 291.78 602.316 269.628C597.556 260.325 588.14 253.906 574.718 250.526L574.728 250.52Z"
          stroke="url(#paint6_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.203 534.678C194.228 531.159 184.381 524.412 179.374 514.618C167.655 491.714 183.45 454.523 222.704 412.568C296.754 333.428 432.65 259.368 525.633 247.473C566.477 242.248 594.312 249.814 604.006 268.766C615.725 291.671 599.93 328.862 560.676 370.816C486.625 449.956 350.729 524.016 257.746 535.912C237.999 538.437 221.285 537.973 208.203 534.678ZM574.728 250.52C561.9 247.29 545.423 246.852 525.868 249.349C433.251 261.194 297.868 334.988 224.077 413.865C185.4 455.199 169.717 491.61 181.054 513.762C190.359 531.952 217.512 539.152 257.502 534.041C350.119 522.196 485.504 448.395 559.292 369.525C597.969 328.191 613.652 291.78 602.316 269.628C597.556 260.325 588.14 253.906 574.718 250.526L574.728 250.52Z"
          stroke="url(#paint7_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M199.88 534.518C183.419 530.373 172.153 522.377 167.065 510.87C155.789 485.368 175.258 445.618 220.479 401.825C303.052 321.86 446.891 251.947 541.127 245.972C580.928 243.448 607.63 252.873 616.311 272.505C627.587 298.007 608.125 337.759 562.897 381.55C480.324 461.515 336.485 531.428 242.249 537.403C225.76 538.452 211.523 537.442 199.882 534.51L199.88 534.518ZM583.067 250.693C571.632 247.814 557.573 246.831 541.248 247.862C447.397 253.816 304.096 323.495 221.793 403.191C177.173 446.403 157.857 485.375 168.796 510.111C177.134 528.966 203.179 537.991 242.134 535.523C335.985 529.569 479.294 459.892 561.589 380.194C606.209 336.982 625.524 298.01 614.586 273.274C609.746 262.318 598.918 254.685 583.067 250.693Z"
          stroke="url(#paint8_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M199.88 534.518C183.419 530.373 172.153 522.377 167.065 510.87C155.789 485.368 175.258 445.618 220.479 401.825C303.052 321.86 446.891 251.947 541.127 245.972C580.928 243.448 607.63 252.873 616.311 272.505C627.587 298.007 608.125 337.759 562.897 381.55C480.324 461.515 336.485 531.428 242.249 537.403C225.76 538.452 211.523 537.442 199.882 534.51L199.88 534.518ZM583.067 250.693C571.632 247.814 557.573 246.831 541.248 247.862C447.397 253.816 304.096 323.495 221.793 403.191C177.173 446.403 157.857 485.375 168.796 510.111C177.134 528.966 203.179 537.991 242.134 535.523C335.985 529.569 479.294 459.892 561.589 380.194C606.209 336.982 625.524 298.01 614.586 273.274C609.746 262.318 598.918 254.685 583.067 250.693Z"
          stroke="url(#paint9_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M199.88 534.518C183.419 530.373 172.153 522.377 167.065 510.87C155.789 485.368 175.258 445.618 220.479 401.825C303.052 321.86 446.891 251.947 541.127 245.972C580.928 243.448 607.63 252.873 616.311 272.505C627.587 298.007 608.125 337.759 562.897 381.55C480.324 461.515 336.485 531.428 242.249 537.403C225.76 538.452 211.523 537.442 199.882 534.51L199.88 534.518ZM583.067 250.693C571.632 247.814 557.573 246.831 541.248 247.862C447.397 253.816 304.096 323.495 221.793 403.191C177.173 446.403 157.857 485.375 168.796 510.111C177.134 528.966 203.179 537.991 242.134 535.523C335.985 529.569 479.294 459.892 561.589 380.194C606.209 336.982 625.524 298.01 614.586 273.274C609.746 262.318 598.918 254.685 583.067 250.693Z"
          stroke="url(#paint10_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M199.88 534.518C183.419 530.373 172.153 522.377 167.065 510.87C155.789 485.368 175.258 445.618 220.479 401.825C303.052 321.86 446.891 251.947 541.127 245.972C580.928 243.448 607.63 252.873 616.311 272.505C627.587 298.007 608.125 337.759 562.897 381.55C480.324 461.515 336.485 531.428 242.249 537.403C225.76 538.452 211.523 537.442 199.882 534.51L199.88 534.518ZM583.067 250.693C571.632 247.814 557.573 246.831 541.248 247.862C447.397 253.816 304.096 323.495 221.793 403.191C177.173 446.403 157.857 485.375 168.796 510.111C177.134 528.966 203.179 537.991 242.134 535.523C335.985 529.569 479.294 459.892 561.589 380.194C606.209 336.982 625.524 298.01 614.586 273.274C609.746 262.318 598.918 254.685 583.067 250.693Z"
          stroke="url(#paint11_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M192.161 534.054C172.812 529.182 160.189 519.725 155.064 506.021C144.451 477.656 167.903 435.391 219.416 390.07C310.595 309.849 461.931 244.987 556.783 245.487C595.855 245.691 620.591 256.709 628.32 277.346C638.934 305.712 615.482 347.976 563.968 393.298C472.789 473.519 321.453 538.38 226.603 537.872C213.475 537.806 201.96 536.514 192.171 534.048L192.161 534.054ZM590.769 251.151C581.13 248.724 569.77 247.453 556.768 247.385C462.32 246.886 311.542 311.535 220.665 391.495C169.775 436.275 146.509 477.77 156.837 505.365C164.262 525.202 188.389 535.798 226.613 535.998C321.061 536.497 471.838 471.849 562.716 391.888C613.605 347.108 636.872 305.613 626.544 278.018C621.644 264.927 609.469 255.86 590.769 251.151Z"
          stroke="url(#paint12_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M192.161 534.054C172.812 529.182 160.189 519.725 155.064 506.021C144.451 477.656 167.903 435.391 219.416 390.07C310.595 309.849 461.931 244.987 556.783 245.487C595.855 245.691 620.591 256.709 628.32 277.346C638.934 305.712 615.482 347.976 563.968 393.298C472.789 473.519 321.453 538.38 226.603 537.872C213.475 537.806 201.96 536.514 192.171 534.048L192.161 534.054ZM590.769 251.151C581.13 248.724 569.77 247.453 556.768 247.385C462.32 246.886 311.542 311.535 220.665 391.495C169.775 436.275 146.509 477.77 156.837 505.365C164.262 525.202 188.389 535.798 226.613 535.998C321.061 536.497 471.838 471.849 562.716 391.888C613.605 347.108 636.872 305.613 626.544 278.018C621.644 264.927 609.469 255.86 590.769 251.151Z"
          stroke="url(#paint13_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M192.161 534.054C172.812 529.182 160.189 519.725 155.064 506.021C144.451 477.656 167.903 435.391 219.416 390.07C310.595 309.849 461.931 244.987 556.783 245.487C595.855 245.691 620.591 256.709 628.32 277.346C638.934 305.712 615.482 347.976 563.968 393.298C472.789 473.519 321.453 538.38 226.603 537.872C213.475 537.806 201.96 536.514 192.171 534.048L192.161 534.054ZM590.769 251.151C581.13 248.724 569.77 247.453 556.768 247.385C462.32 246.886 311.542 311.535 220.665 391.495C169.775 436.275 146.509 477.77 156.837 505.365C164.262 525.202 188.389 535.798 226.613 535.998C321.061 536.497 471.838 471.849 562.716 391.888C613.605 347.108 636.872 305.613 626.544 278.018C621.644 264.927 609.469 255.86 590.769 251.151Z"
          stroke="url(#paint14_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M192.161 534.054C172.812 529.182 160.189 519.725 155.064 506.021C144.451 477.656 167.903 435.391 219.416 390.07C310.595 309.849 461.931 244.987 556.783 245.487C595.855 245.691 620.591 256.709 628.32 277.346C638.934 305.712 615.482 347.976 563.968 393.298C472.789 473.519 321.453 538.38 226.603 537.872C213.475 537.806 201.96 536.514 192.171 534.048L192.161 534.054ZM590.769 251.151C581.13 248.724 569.77 247.453 556.768 247.385C462.32 246.886 311.542 311.535 220.665 391.495C169.775 436.275 146.509 477.77 156.837 505.365C164.262 525.202 188.389 535.798 226.613 535.998C321.061 536.497 471.838 471.849 562.716 391.888C613.605 347.108 636.872 305.613 626.544 278.018C621.644 264.927 609.469 255.86 590.769 251.151Z"
          stroke="url(#paint15_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M572.485 246.133C477.728 238.604 319.433 297.447 219.625 377.312C161.564 423.771 133.801 468.484 143.467 499.977C148.445 516.202 163.054 527.531 186.21 533.362C193.585 535.219 201.824 536.52 210.9 537.24C305.657 544.769 463.952 485.926 563.76 406.061C621.82 359.602 649.584 314.889 639.918 283.396C633.356 262 610.032 249.117 572.485 246.133ZM572.485 246.133L572.408 247.073M596.729 251.851C589.456 250.019 581.314 248.735 572.337 248.023C477.994 240.523 320.293 299.178 220.81 378.789C164.224 424.07 135.987 469.161 145.273 499.422C151.593 520.008 174.333 532.433 211.048 535.35C305.399 542.852 463.092 484.195 562.575 404.584C619.161 359.303 647.398 314.212 638.112 283.951C633.343 268.401 619.188 257.507 596.729 251.851Z"
          stroke="url(#paint16_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M572.485 246.133C477.728 238.604 319.433 297.447 219.625 377.312C161.564 423.771 133.801 468.484 143.467 499.977C148.445 516.202 163.054 527.531 186.21 533.362C193.585 535.219 201.824 536.52 210.9 537.24C305.657 544.769 463.952 485.926 563.76 406.061C621.82 359.602 649.584 314.889 639.918 283.396C633.356 262 610.032 249.117 572.485 246.133ZM572.485 246.133L572.408 247.073M596.729 251.851C589.456 250.019 581.314 248.735 572.337 248.023C477.994 240.523 320.293 299.178 220.81 378.789C164.224 424.07 135.987 469.161 145.273 499.422C151.593 520.008 174.333 532.433 211.048 535.35C305.399 542.852 463.092 484.195 562.575 404.584C619.161 359.303 647.398 314.212 638.112 283.951C633.343 268.401 619.188 257.507 596.729 251.851Z"
          stroke="url(#paint17_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M572.485 246.133C477.728 238.604 319.433 297.447 219.625 377.312C161.564 423.771 133.801 468.484 143.467 499.977C148.445 516.202 163.054 527.531 186.21 533.362C193.585 535.219 201.824 536.52 210.9 537.24C305.657 544.769 463.952 485.926 563.76 406.061C621.82 359.602 649.584 314.889 639.918 283.396C633.356 262 610.032 249.117 572.485 246.133ZM572.485 246.133L572.408 247.073M596.729 251.851C589.456 250.019 581.314 248.735 572.337 248.023C477.994 240.523 320.293 299.178 220.81 378.789C164.224 424.07 135.987 469.161 145.273 499.422C151.593 520.008 174.333 532.433 211.048 535.35C305.399 542.852 463.092 484.195 562.575 404.584C619.161 359.303 647.398 314.212 638.112 283.951C633.343 268.401 619.188 257.507 596.729 251.851Z"
          stroke="url(#paint18_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M572.485 246.133C477.728 238.604 319.433 297.447 219.625 377.312C161.564 423.771 133.801 468.484 143.467 499.977C148.445 516.202 163.054 527.531 186.21 533.362C193.585 535.219 201.824 536.52 210.9 537.24C305.657 544.769 463.952 485.926 563.76 406.061C621.82 359.602 649.584 314.889 639.918 283.396C633.356 262 610.032 249.117 572.485 246.133ZM572.485 246.133L572.408 247.073M596.729 251.851C589.456 250.019 581.314 248.735 572.337 248.023C477.994 240.523 320.293 299.178 220.81 378.789C164.224 424.07 135.987 469.161 145.273 499.422C151.593 520.008 174.333 532.433 211.048 535.35C305.399 542.852 463.092 484.195 562.575 404.584C619.161 359.303 647.398 314.212 638.112 283.951C633.343 268.401 619.188 257.507 596.729 251.851Z"
          stroke="url(#paint19_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M181.989 532.679C154.037 525.64 136.98 511.936 132.366 492.63C124.152 458.246 157.36 409.989 221.204 363.557C329.601 284.709 494.203 232.867 588.115 247.992C623.96 253.762 645.701 268.553 651.008 290.752C659.222 325.136 626.014 373.393 562.17 419.825C453.766 498.671 289.171 550.515 195.259 535.39C190.593 534.636 186.167 533.731 181.989 532.679ZM587.965 248.922L587.815 249.852C494.332 234.793 330.373 286.486 222.318 365.083C159.112 411.053 126.174 458.57 134.209 492.194C139.323 513.595 160.541 527.884 195.561 533.522C289.044 548.581 453.003 496.888 561.058 418.291C624.264 372.321 657.202 324.804 649.165 291.188C644.052 269.787 622.833 255.498 587.805 249.858L587.955 248.928L587.965 248.922Z"
          stroke="url(#paint20_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M181.989 532.679C154.037 525.64 136.98 511.936 132.366 492.63C124.152 458.246 157.36 409.989 221.204 363.557C329.601 284.709 494.203 232.867 588.115 247.992C623.96 253.762 645.701 268.553 651.008 290.752C659.222 325.136 626.014 373.393 562.17 419.825C453.766 498.671 289.171 550.515 195.259 535.39C190.593 534.636 186.167 533.731 181.989 532.679ZM587.965 248.922L587.815 249.852C494.332 234.793 330.373 286.486 222.318 365.083C159.112 411.053 126.174 458.57 134.209 492.194C139.323 513.595 160.541 527.884 195.561 533.522C289.044 548.581 453.003 496.888 561.058 418.291C624.264 372.321 657.202 324.804 649.165 291.188C644.052 269.787 622.833 255.498 587.805 249.858L587.955 248.928L587.965 248.922Z"
          stroke="url(#paint21_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M181.989 532.679C154.037 525.64 136.98 511.936 132.366 492.63C124.152 458.246 157.36 409.989 221.204 363.557C329.601 284.709 494.203 232.867 588.115 247.992C623.96 253.762 645.701 268.553 651.008 290.752C659.222 325.136 626.014 373.393 562.17 419.825C453.766 498.671 289.171 550.515 195.259 535.39C190.593 534.636 186.167 533.731 181.989 532.679ZM587.965 248.922L587.815 249.852C494.332 234.793 330.373 286.486 222.318 365.083C159.112 411.053 126.174 458.57 134.209 492.194C139.323 513.595 160.541 527.884 195.561 533.522C289.044 548.581 453.003 496.888 561.058 418.291C624.264 372.321 657.202 324.804 649.165 291.188C644.052 269.787 622.833 255.498 587.805 249.858L587.955 248.928L587.965 248.922Z"
          stroke="url(#paint22_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M181.989 532.679C154.037 525.64 136.98 511.936 132.366 492.63C124.152 458.246 157.36 409.989 221.204 363.557C329.601 284.709 494.203 232.867 588.115 247.992C623.96 253.762 645.701 268.553 651.008 290.752C659.222 325.136 626.014 373.393 562.17 419.825C453.766 498.671 289.171 550.515 195.259 535.39C190.593 534.636 186.167 533.731 181.989 532.679ZM587.965 248.922L587.815 249.852C494.332 234.793 330.373 286.486 222.318 365.083C159.112 411.053 126.174 458.57 134.209 492.194C139.323 513.595 160.541 527.884 195.561 533.522C289.044 548.581 453.003 496.888 561.058 418.291C624.264 372.321 657.202 324.804 649.165 291.188C644.052 269.787 622.833 255.498 587.805 249.858L587.955 248.928L587.965 248.922Z"
          stroke="url(#paint23_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M179.876 532.248L179.829 532.236C145.857 523.673 125.834 506.941 121.913 483.846C115.461 445.841 153.724 395.356 224.269 348.818C341.129 271.715 511.234 227.898 603.492 251.132L603.539 251.144C637.51 259.707 657.534 276.439 661.454 299.534C667.907 337.539 629.635 388.022 559.099 434.561C442.238 511.665 272.133 555.481 179.876 532.248ZM603.039 252.962C511.225 229.84 341.805 273.543 225.319 350.396C155.419 396.515 117.461 446.286 123.784 483.534C127.57 505.83 147.111 522.036 180.299 530.402L180.346 530.414C272.161 553.536 441.588 509.835 558.067 432.98C627.967 386.861 665.924 337.09 659.601 299.841C655.816 277.546 636.274 261.34 603.087 252.974L603.039 252.962Z"
          stroke="url(#paint24_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.876 532.248L179.829 532.236C145.857 523.673 125.834 506.941 121.913 483.846C115.461 445.841 153.724 395.356 224.269 348.818C341.129 271.715 511.234 227.898 603.492 251.132L603.539 251.144C637.51 259.707 657.534 276.439 661.454 299.534C667.907 337.539 629.635 388.022 559.099 434.561C442.238 511.665 272.133 555.481 179.876 532.248ZM603.039 252.962C511.225 229.84 341.805 273.543 225.319 350.396C155.419 396.515 117.461 446.286 123.784 483.534C127.57 505.83 147.111 522.036 180.299 530.402L180.346 530.414C272.161 553.536 441.588 509.835 558.067 432.98C627.967 386.861 665.924 337.09 659.601 299.841C655.816 277.546 636.274 261.34 603.087 252.974L603.039 252.962Z"
          stroke="url(#paint25_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.876 532.248L179.829 532.236C145.857 523.673 125.834 506.941 121.913 483.846C115.461 445.841 153.724 395.356 224.269 348.818C341.129 271.715 511.234 227.898 603.492 251.132L603.539 251.144C637.51 259.707 657.534 276.439 661.454 299.534C667.907 337.539 629.635 388.022 559.099 434.561C442.238 511.665 272.133 555.481 179.876 532.248ZM603.039 252.962C511.225 229.84 341.805 273.543 225.319 350.396C155.419 396.515 117.461 446.286 123.784 483.534C127.57 505.83 147.111 522.036 180.299 530.402L180.346 530.414C272.161 553.536 441.588 509.835 558.067 432.98C627.967 386.861 665.924 337.09 659.601 299.841C655.816 277.546 636.274 261.34 603.087 252.974L603.039 252.962Z"
          stroke="url(#paint26_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.876 532.248L179.829 532.236C145.857 523.673 125.834 506.941 121.913 483.846C115.461 445.841 153.724 395.356 224.269 348.818C341.129 271.715 511.234 227.898 603.492 251.132L603.539 251.144C637.51 259.707 657.534 276.439 661.454 299.534C667.907 337.539 629.635 388.022 559.099 434.561C442.238 511.665 272.133 555.481 179.876 532.248ZM603.039 252.962C511.225 229.84 341.805 273.543 225.319 350.396C155.419 396.515 117.461 446.286 123.784 483.534C127.57 505.83 147.111 522.036 180.299 530.402L180.346 530.414C272.161 553.536 441.588 509.835 558.067 432.98C627.967 386.861 665.924 337.09 659.601 299.841C655.816 277.546 636.274 261.34 603.087 252.974L603.039 252.962Z"
          stroke="url(#paint27_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M179.977 532.282C174.659 530.942 169.581 529.419 164.738 527.695C132.763 516.318 114.61 497.575 112.244 473.483C108.128 431.615 151.755 379.148 228.925 333.134C354.1 258.504 528.92 223.768 618.631 255.676C650.606 267.053 668.76 285.796 671.125 309.888C675.233 351.755 631.614 404.223 554.444 450.237C436.023 520.837 273.162 555.74 179.977 532.282ZM602.875 252.912C510.158 229.563 347.946 264.393 229.902 334.769C153.368 380.396 110.089 432.184 114.133 473.302C116.418 496.608 134.139 514.797 165.381 525.913C254.611 557.658 428.724 522.988 553.481 448.614C630.015 402.987 673.294 351.199 669.25 310.081C666.965 286.775 649.244 268.586 618.002 257.47C613.198 255.764 608.143 254.247 602.873 252.92L602.875 252.912Z"
          stroke="url(#paint28_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.977 532.282C174.659 530.942 169.581 529.419 164.738 527.695C132.763 516.318 114.61 497.575 112.244 473.483C108.128 431.615 151.755 379.148 228.925 333.134C354.1 258.504 528.92 223.768 618.631 255.676C650.606 267.053 668.76 285.796 671.125 309.888C675.233 351.755 631.614 404.223 554.444 450.237C436.023 520.837 273.162 555.74 179.977 532.282ZM602.875 252.912C510.158 229.563 347.946 264.393 229.902 334.769C153.368 380.396 110.089 432.184 114.133 473.302C116.418 496.608 134.139 514.797 165.381 525.913C254.611 557.658 428.724 522.988 553.481 448.614C630.015 402.987 673.294 351.199 669.25 310.081C666.965 286.775 649.244 268.586 618.002 257.47C613.198 255.764 608.143 254.247 602.873 252.92L602.875 252.912Z"
          stroke="url(#paint29_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.977 532.282C174.659 530.942 169.581 529.419 164.738 527.695C132.763 516.318 114.61 497.575 112.244 473.483C108.128 431.615 151.755 379.148 228.925 333.134C354.1 258.504 528.92 223.768 618.631 255.676C650.606 267.053 668.76 285.796 671.125 309.888C675.233 351.755 631.614 404.223 554.444 450.237C436.023 520.837 273.162 555.74 179.977 532.282ZM602.875 252.912C510.158 229.563 347.946 264.393 229.902 334.769C153.368 380.396 110.089 432.184 114.133 473.302C116.418 496.608 134.139 514.797 165.381 525.913C254.611 557.658 428.724 522.988 553.481 448.614C630.015 402.987 673.294 351.199 669.25 310.081C666.965 286.775 649.244 268.586 618.002 257.47C613.198 255.764 608.143 254.247 602.873 252.92L602.875 252.912Z"
          stroke="url(#paint30_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M179.977 532.282C174.659 530.942 169.581 529.419 164.738 527.695C132.763 516.318 114.61 497.575 112.244 473.483C108.128 431.615 151.755 379.148 228.925 333.134C354.1 258.504 528.92 223.768 618.631 255.676C650.606 267.053 668.76 285.796 671.125 309.888C675.233 351.755 631.614 404.223 554.444 450.237C436.023 520.837 273.162 555.74 179.977 532.282ZM602.875 252.912C510.158 229.563 347.946 264.393 229.902 334.769C153.368 380.396 110.089 432.184 114.133 473.302C116.418 496.608 134.139 514.797 165.381 525.913C254.611 557.658 428.724 522.988 553.481 448.614C630.015 402.987 673.294 351.199 669.25 310.081C666.965 286.775 649.244 268.586 618.002 257.47C613.198 255.764 608.143 254.247 602.873 252.92L602.875 252.912Z"
          stroke="url(#paint31_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M182.609 533.087C170.739 530.098 159.861 526.306 150.137 521.678C120.245 507.443 104.14 486.581 103.54 461.361C102.461 415.451 151.708 361.32 235.277 316.564C368.472 245.241 547.003 220.63 633.227 261.708C663.119 275.943 679.224 296.804 679.824 322.025C680.903 367.934 631.656 422.066 548.087 466.822C429.901 530.109 276.03 556.613 182.607 533.095L182.609 533.087ZM600.196 252.085C507.258 228.68 353.987 255.142 236.178 318.23C153.251 362.643 104.378 416.128 105.438 461.308C106.012 485.765 121.755 506.056 150.955 519.965C160.597 524.556 171.413 528.323 183.188 531.289C276.126 554.694 429.397 528.232 547.205 465.144C630.132 420.731 679.014 367.248 677.945 322.066C677.372 297.609 661.628 277.318 632.428 263.409C622.787 258.818 611.971 255.05 600.196 252.085Z"
          stroke="url(#paint32_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M182.609 533.087C170.739 530.098 159.861 526.306 150.137 521.678C120.245 507.443 104.14 486.581 103.54 461.361C102.461 415.451 151.708 361.32 235.277 316.564C368.472 245.241 547.003 220.63 633.227 261.708C663.119 275.943 679.224 296.804 679.824 322.025C680.903 367.934 631.656 422.066 548.087 466.822C429.901 530.109 276.03 556.613 182.607 533.095L182.609 533.087ZM600.196 252.085C507.258 228.68 353.987 255.142 236.178 318.23C153.251 362.643 104.378 416.128 105.438 461.308C106.012 485.765 121.755 506.056 150.955 519.965C160.597 524.556 171.413 528.323 183.188 531.289C276.126 554.694 429.397 528.232 547.205 465.144C630.132 420.731 679.014 367.248 677.945 322.066C677.372 297.609 661.628 277.318 632.428 263.409C622.787 258.818 611.971 255.05 600.196 252.085Z"
          stroke="url(#paint33_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M182.609 533.087C170.739 530.098 159.861 526.306 150.137 521.678C120.245 507.443 104.14 486.581 103.54 461.361C102.461 415.451 151.708 361.32 235.277 316.564C368.472 245.241 547.003 220.63 633.227 261.708C663.119 275.943 679.224 296.804 679.824 322.025C680.903 367.934 631.656 422.066 548.087 466.822C429.901 530.109 276.03 556.613 182.607 533.095L182.609 533.087ZM600.196 252.085C507.258 228.68 353.987 255.142 236.178 318.23C153.251 362.643 104.378 416.128 105.438 461.308C106.012 485.765 121.755 506.056 150.955 519.965C160.597 524.556 171.413 528.323 183.188 531.289C276.126 554.694 429.397 528.232 547.205 465.144C630.132 420.731 679.014 367.248 677.945 322.066C677.372 297.609 661.628 277.318 632.428 263.409C622.787 258.818 611.971 255.05 600.196 252.085Z"
          stroke="url(#paint34_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M182.609 533.087C170.739 530.098 159.861 526.306 150.137 521.678C120.245 507.443 104.14 486.581 103.54 461.361C102.461 415.451 151.708 361.32 235.277 316.564C368.472 245.241 547.003 220.63 633.227 261.708C663.119 275.943 679.224 296.804 679.824 322.025C680.903 367.934 631.656 422.066 548.087 466.822C429.901 530.109 276.03 556.613 182.607 533.095L182.609 533.087ZM600.196 252.085C507.258 228.68 353.987 255.142 236.178 318.23C153.251 362.643 104.378 416.128 105.438 461.308C106.012 485.765 121.755 506.056 150.955 519.965C160.597 524.556 171.413 528.323 183.188 531.289C276.126 554.694 429.397 528.232 547.205 465.144C630.132 420.731 679.014 367.248 677.945 322.066C677.372 297.609 661.628 277.318 632.428 263.409C622.787 258.818 611.971 255.05 600.196 252.085Z"
          stroke="url(#paint35_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M188.166 535.051C168.239 530.033 150.663 523.065 136.192 514.094C108.461 496.894 94.5743 473.772 96.0313 447.234C98.7876 397.191 153.889 341.833 243.42 299.134C384.288 231.965 565.414 218.568 647.182 269.288C674.913 286.488 688.8 309.61 687.343 336.147C684.586 386.191 629.485 441.549 539.954 484.248C424.02 539.527 280.811 558.382 188.164 535.059L188.166 535.051ZM594.627 250.103C502.465 226.894 359.806 245.736 244.234 300.845C155.335 343.241 100.637 398.002 97.9154 447.338C96.4913 473.144 110.072 495.676 137.177 512.491C218.466 562.904 398.777 549.471 539.13 482.542C628.029 440.147 682.727 385.386 685.441 336.047C686.865 310.242 673.284 287.71 646.177 270.903C631.823 262.003 614.378 255.085 594.617 250.109L594.627 250.103Z"
          stroke="url(#paint36_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M188.166 535.051C168.239 530.033 150.663 523.065 136.192 514.094C108.461 496.894 94.5743 473.772 96.0313 447.234C98.7876 397.191 153.889 341.833 243.42 299.134C384.288 231.965 565.414 218.568 647.182 269.288C674.913 286.488 688.8 309.61 687.343 336.147C684.586 386.191 629.485 441.549 539.954 484.248C424.02 539.527 280.811 558.382 188.164 535.059L188.166 535.051ZM594.627 250.103C502.465 226.894 359.806 245.736 244.234 300.845C155.335 343.241 100.637 398.002 97.9154 447.338C96.4913 473.144 110.072 495.676 137.177 512.491C218.466 562.904 398.777 549.471 539.13 482.542C628.029 440.147 682.727 385.386 685.441 336.047C686.865 310.242 673.284 287.71 646.177 270.903C631.823 262.003 614.378 255.085 594.617 250.109L594.627 250.103Z"
          stroke="url(#paint37_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M188.166 535.051C168.239 530.033 150.663 523.065 136.192 514.094C108.461 496.894 94.5743 473.772 96.0313 447.234C98.7876 397.191 153.889 341.833 243.42 299.134C384.288 231.965 565.414 218.568 647.182 269.288C674.913 286.488 688.8 309.61 687.343 336.147C684.586 386.191 629.485 441.549 539.954 484.248C424.02 539.527 280.811 558.382 188.164 535.059L188.166 535.051ZM594.627 250.103C502.465 226.894 359.806 245.736 244.234 300.845C155.335 343.241 100.637 398.002 97.9154 447.338C96.4913 473.144 110.072 495.676 137.177 512.491C218.466 562.904 398.777 549.471 539.13 482.542C628.029 440.147 682.727 385.386 685.441 336.047C686.865 310.242 673.284 287.71 646.177 270.903C631.823 262.003 614.378 255.085 594.617 250.109L594.627 250.103Z"
          stroke="url(#paint38_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M188.166 535.051C168.239 530.033 150.663 523.065 136.192 514.094C108.461 496.894 94.5743 473.772 96.0313 447.234C98.7876 397.191 153.889 341.833 243.42 299.134C384.288 231.965 565.414 218.568 647.182 269.288C674.913 286.488 688.8 309.61 687.343 336.147C684.586 386.191 629.485 441.549 539.954 484.248C424.02 539.527 280.811 558.382 188.164 535.059L188.166 535.051ZM594.627 250.103C502.465 226.894 359.806 245.736 244.234 300.845C155.335 343.241 100.637 398.002 97.9154 447.338C96.4913 473.144 110.072 495.676 137.177 512.491C218.466 562.904 398.777 549.471 539.13 482.542C628.029 440.147 682.727 385.386 685.441 336.047C686.865 310.242 673.284 287.71 646.177 270.903C631.823 262.003 614.378 255.085 594.617 250.109L594.627 250.103Z"
          stroke="url(#paint39_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M196.93 538.613C167.238 531.135 141.911 519.893 123.072 504.873C97.5529 484.536 86.1252 458.936 90.0153 430.857C97.5209 376.759 158.621 320.706 253.451 280.924C401.53 218.802 584.051 217.715 660.322 278.496C685.842 298.833 697.27 324.433 693.379 352.512C685.874 406.611 624.774 462.663 529.944 502.445C418.448 549.223 287.425 561.394 196.932 538.605L196.93 538.613ZM585.892 246.531C495.866 223.859 365.33 236.035 254.172 282.671C159.962 322.197 99.2888 377.692 91.8833 431.125C88.0834 458.512 99.2767 483.506 124.243 503.401C200.028 563.806 381.695 562.597 529.203 500.71C623.413 461.184 684.086 405.689 691.492 352.256C695.292 324.869 684.098 299.875 659.132 279.98C640.454 265.093 615.338 253.946 585.892 246.531Z"
          stroke="url(#paint40_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M196.93 538.613C167.238 531.135 141.911 519.893 123.072 504.873C97.5529 484.536 86.1252 458.936 90.0153 430.857C97.5209 376.759 158.621 320.706 253.451 280.924C401.53 218.802 584.051 217.715 660.322 278.496C685.842 298.833 697.27 324.433 693.379 352.512C685.874 406.611 624.774 462.663 529.944 502.445C418.448 549.223 287.425 561.394 196.932 538.605L196.93 538.613ZM585.892 246.531C495.866 223.859 365.33 236.035 254.172 282.671C159.962 322.197 99.2888 377.692 91.8833 431.125C88.0834 458.512 99.2767 483.506 124.243 503.401C200.028 563.806 381.695 562.597 529.203 500.71C623.413 461.184 684.086 405.689 691.492 352.256C695.292 324.869 684.098 299.875 659.132 279.98C640.454 265.093 615.338 253.946 585.892 246.531Z"
          stroke="url(#paint41_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M196.93 538.613C167.238 531.135 141.911 519.893 123.072 504.873C97.5529 484.536 86.1252 458.936 90.0153 430.857C97.5209 376.759 158.621 320.706 253.451 280.924C401.53 218.802 584.051 217.715 660.322 278.496C685.842 298.833 697.27 324.433 693.379 352.512C685.874 406.611 624.774 462.663 529.944 502.445C418.448 549.223 287.425 561.394 196.932 538.605L196.93 538.613ZM585.892 246.531C495.866 223.859 365.33 236.035 254.172 282.671C159.962 322.197 99.2888 377.692 91.8833 431.125C88.0834 458.512 99.2767 483.506 124.243 503.401C200.028 563.806 381.695 562.597 529.203 500.71C623.413 461.184 684.086 405.689 691.492 352.256C695.292 324.869 684.098 299.875 659.132 279.98C640.454 265.093 615.338 253.946 585.892 246.531Z"
          stroke="url(#paint42_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M196.93 538.613C167.238 531.135 141.911 519.893 123.072 504.873C97.5529 484.536 86.1252 458.936 90.0153 430.857C97.5209 376.759 158.621 320.706 253.451 280.924C401.53 218.802 584.051 217.715 660.322 278.496C685.842 298.833 697.27 324.433 693.379 352.512C685.874 406.611 624.774 462.663 529.944 502.445C418.448 549.223 287.425 561.394 196.932 538.605L196.93 538.613ZM585.892 246.531C495.866 223.859 365.33 236.035 254.172 282.671C159.962 322.197 99.2888 377.692 91.8833 431.125C88.0834 458.512 99.2767 483.506 124.243 503.401C200.028 563.806 381.695 562.597 529.203 500.71C623.413 461.184 684.086 405.689 691.492 352.256C695.292 324.869 684.098 299.875 659.132 279.98C640.454 265.093 615.338 253.946 585.892 246.531Z"
          stroke="url(#paint43_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M208.957 544.217C167.664 533.818 133.432 516.967 110.931 493.965C87.7041 470.207 79.0243 441.849 85.845 411.958C99.0515 354.069 166.186 298.013 265.426 262.009C420.179 205.868 602.763 218.162 672.442 289.417C695.669 313.174 704.341 341.531 697.528 371.424C684.322 429.312 617.187 485.369 517.947 521.373C413.151 559.394 295.572 566.03 208.957 544.217ZM573.912 240.931C487.724 219.226 370.563 225.874 266.072 263.787C167.434 299.573 100.756 355.121 87.6909 412.381C81.0179 441.619 89.5225 469.369 112.282 492.647C181.492 563.422 363.176 575.514 517.299 519.602C615.929 483.815 682.615 428.268 695.68 371.009C702.353 341.771 693.849 314.02 671.089 290.742C648.804 267.954 614.849 251.24 573.912 240.931Z"
          stroke="url(#paint44_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.957 544.217C167.664 533.818 133.432 516.967 110.931 493.965C87.7041 470.207 79.0243 441.849 85.845 411.958C99.0515 354.069 166.186 298.013 265.426 262.009C420.179 205.868 602.763 218.162 672.442 289.417C695.669 313.174 704.341 341.531 697.528 371.424C684.322 429.312 617.187 485.369 517.947 521.373C413.151 559.394 295.572 566.03 208.957 544.217ZM573.912 240.931C487.724 219.226 370.563 225.874 266.072 263.787C167.434 299.573 100.756 355.121 87.6909 412.381C81.0179 441.619 89.5225 469.369 112.282 492.647C181.492 563.422 363.176 575.514 517.299 519.602C615.929 483.815 682.615 428.268 695.68 371.009C702.353 341.771 693.849 314.02 671.089 290.742C648.804 267.954 614.849 251.24 573.912 240.931Z"
          stroke="url(#paint45_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.957 544.217C167.664 533.818 133.432 516.967 110.931 493.965C87.7041 470.207 79.0243 441.849 85.845 411.958C99.0515 354.069 166.186 298.013 265.426 262.009C420.179 205.868 602.763 218.162 672.442 289.417C695.669 313.174 704.341 341.531 697.528 371.424C684.322 429.312 617.187 485.369 517.947 521.373C413.151 559.394 295.572 566.03 208.957 544.217ZM573.912 240.931C487.724 219.226 370.563 225.874 266.072 263.787C167.434 299.573 100.756 355.121 87.6909 412.381C81.0179 441.619 89.5225 469.369 112.282 492.647C181.492 563.422 363.176 575.514 517.299 519.602C615.929 483.815 682.615 428.268 695.68 371.009C702.353 341.771 693.849 314.02 671.089 290.742C648.804 267.954 614.849 251.24 573.912 240.931Z"
          stroke="url(#paint46_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M208.957 544.217C167.664 533.818 133.432 516.967 110.931 493.965C87.7041 470.207 79.0243 441.849 85.845 411.958C99.0515 354.069 166.186 298.013 265.426 262.009C420.179 205.868 602.763 218.162 672.442 289.417C695.669 313.174 704.341 341.531 697.528 371.424C684.322 429.312 617.187 485.369 517.947 521.373C413.151 559.394 295.572 566.03 208.957 544.217ZM573.912 240.931C487.724 219.226 370.563 225.874 266.072 263.787C167.434 299.573 100.756 355.121 87.6909 412.381C81.0179 441.619 89.5225 469.369 112.282 492.647C181.492 563.422 363.176 575.514 517.299 519.602C615.929 483.815 682.615 428.268 695.68 371.009C702.353 341.771 693.849 314.02 671.089 290.742C648.804 267.954 614.849 251.24 573.912 240.931Z"
          stroke="url(#paint47_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M223.957 552.227C169.353 538.476 124.979 514.375 99.9967 481.28C79.1847 453.713 73.6673 422.221 84.0483 390.222C103.883 329.071 176.93 273.831 279.463 242.46C440.235 193.263 621.443 220.012 683.398 302.089C704.21 329.656 709.727 361.148 699.346 393.147C679.511 454.298 606.464 509.539 503.931 540.909C407.99 570.263 304.771 572.579 223.965 552.229L223.957 552.227ZM558.987 232.93C478.552 212.674 375.684 215.003 280.006 244.28C178.068 275.464 105.48 330.248 85.8445 390.809C75.6684 422.195 81.076 453.087 101.503 480.145C163.015 561.639 343.297 588.088 503.368 539.101C605.309 507.91 677.894 453.134 697.54 392.566C707.716 361.18 702.3 330.287 681.881 303.23C657.132 270.446 613.142 246.56 558.989 232.923L558.987 232.93Z"
          stroke="url(#paint48_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M223.957 552.227C169.353 538.476 124.979 514.375 99.9967 481.28C79.1847 453.713 73.6673 422.221 84.0483 390.222C103.883 329.071 176.93 273.831 279.463 242.46C440.235 193.263 621.443 220.012 683.398 302.089C704.21 329.656 709.727 361.148 699.346 393.147C679.511 454.298 606.464 509.539 503.931 540.909C407.99 570.263 304.771 572.579 223.965 552.229L223.957 552.227ZM558.987 232.93C478.552 212.674 375.684 215.003 280.006 244.28C178.068 275.464 105.48 330.248 85.8445 390.809C75.6684 422.195 81.076 453.087 101.503 480.145C163.015 561.639 343.297 588.088 503.368 539.101C605.309 507.91 677.894 453.134 697.54 392.566C707.716 361.18 702.3 330.287 681.881 303.23C657.132 270.446 613.142 246.56 558.989 232.923L558.987 232.93Z"
          stroke="url(#paint49_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M223.957 552.227C169.353 538.476 124.979 514.375 99.9967 481.28C79.1847 453.713 73.6673 422.221 84.0483 390.222C103.883 329.071 176.93 273.831 279.463 242.46C440.235 193.263 621.443 220.012 683.398 302.089C704.21 329.656 709.727 361.148 699.346 393.147C679.511 454.298 606.464 509.539 503.931 540.909C407.99 570.263 304.771 572.579 223.965 552.229L223.957 552.227ZM558.987 232.93C478.552 212.674 375.684 215.003 280.006 244.28C178.068 275.464 105.48 330.248 85.8445 390.809C75.6684 422.195 81.076 453.087 101.503 480.145C163.015 561.639 343.297 588.088 503.368 539.101C605.309 507.91 677.894 453.134 697.54 392.566C707.716 361.18 702.3 330.287 681.881 303.23C657.132 270.446 613.142 246.56 558.989 232.923L558.987 232.93Z"
          stroke="url(#paint50_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M223.957 552.227C169.353 538.476 124.979 514.375 99.9967 481.28C79.1847 453.713 73.6673 422.221 84.0483 390.222C103.883 329.071 176.93 273.831 279.463 242.46C440.235 193.263 621.443 220.012 683.398 302.089C704.21 329.656 709.727 361.148 699.346 393.147C679.511 454.298 606.464 509.539 503.931 540.909C407.99 570.263 304.771 572.579 223.965 552.229L223.957 552.227ZM558.987 232.93C478.552 212.674 375.684 215.003 280.006 244.28C178.068 275.464 105.48 330.248 85.8445 390.809C75.6684 422.195 81.076 453.087 101.503 480.145C163.015 561.639 343.297 588.088 503.368 539.101C605.309 507.91 677.894 453.134 697.54 392.566C707.716 361.18 702.3 330.287 681.881 303.23C657.132 270.446 613.142 246.56 558.989 232.923L558.987 232.93Z"
          stroke="url(#paint51_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M241.11 562.916C171.77 545.455 116.263 512.163 90.425 466.793C72.2489 434.873 70.429 399.827 85.1666 365.433C112.416 301.84 191.077 248.367 295.588 222.409C461.637 181.164 639.893 223.412 692.951 316.587C711.127 348.506 712.947 383.553 698.209 417.947C670.96 481.539 592.299 535.012 487.788 560.971C402.595 582.133 314.176 581.317 241.11 562.916ZM541.889 222.262C469.131 203.939 381.003 203.138 296.042 224.249C192.106 250.065 113.918 303.127 86.9051 366.182C72.412 400.007 74.2012 434.473 92.0655 465.859C144.733 558.347 322.045 600.197 487.332 559.139C591.268 533.322 669.456 480.26 696.469 417.205C710.962 383.381 709.173 348.915 691.309 317.528C665.717 272.582 610.665 239.59 541.887 222.27L541.889 222.262Z"
          stroke="url(#paint52_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M241.11 562.916C171.77 545.455 116.263 512.163 90.425 466.793C72.2489 434.873 70.429 399.827 85.1666 365.433C112.416 301.84 191.077 248.367 295.588 222.409C461.637 181.164 639.893 223.412 692.951 316.587C711.127 348.506 712.947 383.553 698.209 417.947C670.96 481.539 592.299 535.012 487.788 560.971C402.595 582.133 314.176 581.317 241.11 562.916ZM541.889 222.262C469.131 203.939 381.003 203.138 296.042 224.249C192.106 250.065 113.918 303.127 86.9051 366.182C72.412 400.007 74.2012 434.473 92.0655 465.859C144.733 558.347 322.045 600.197 487.332 559.139C591.268 533.322 669.456 480.26 696.469 417.205C710.962 383.381 709.173 348.915 691.309 317.528C665.717 272.582 610.665 239.59 541.887 222.27L541.889 222.262Z"
          stroke="url(#paint53_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M241.11 562.916C171.77 545.455 116.263 512.163 90.425 466.793C72.2489 434.873 70.429 399.827 85.1666 365.433C112.416 301.84 191.077 248.367 295.588 222.409C461.637 181.164 639.893 223.412 692.951 316.587C711.127 348.506 712.947 383.553 698.209 417.947C670.96 481.539 592.299 535.012 487.788 560.971C402.595 582.133 314.176 581.317 241.11 562.916ZM541.889 222.262C469.131 203.939 381.003 203.138 296.042 224.249C192.106 250.065 113.918 303.127 86.9051 366.182C72.412 400.007 74.2012 434.473 92.0655 465.859C144.733 558.347 322.045 600.197 487.332 559.139C591.268 533.322 669.456 480.26 696.469 417.205C710.962 383.381 709.173 348.915 691.309 317.528C665.717 272.582 610.665 239.59 541.887 222.27L541.889 222.262Z"
          stroke="url(#paint54_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M241.11 562.916C171.77 545.455 116.263 512.163 90.425 466.793C72.2489 434.873 70.429 399.827 85.1666 365.433C112.416 301.84 191.077 248.367 295.588 222.409C461.637 181.164 639.893 223.412 692.951 316.587C711.127 348.506 712.947 383.553 698.209 417.947C670.96 481.539 592.299 535.012 487.788 560.971C402.595 582.133 314.176 581.317 241.11 562.916ZM541.889 222.262C469.131 203.939 381.003 203.138 296.042 224.249C192.106 250.065 113.918 303.127 86.9051 366.182C72.412 400.007 74.2012 434.473 92.0655 465.859C144.733 558.347 322.045 600.197 487.332 559.139C591.268 533.322 669.456 480.26 696.469 417.205C710.962 383.381 709.173 348.915 691.309 317.528C665.717 272.582 610.665 239.59 541.887 222.27L541.889 222.262Z"
          stroke="url(#paint55_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M259.146 576.338C174.209 554.948 106.993 510.174 82.4303 450.449C67.2416 413.517 69.8227 374.429 89.889 337.421C125.091 272.491 208.829 221.853 313.883 201.956C484.34 169.673 657.976 228.43 700.944 332.933C716.133 369.864 713.551 408.952 693.485 445.96C658.283 510.891 574.545 561.529 469.491 581.425C396.454 595.255 322.82 592.373 259.146 576.338ZM523.88 208.857C460.452 192.884 387.068 190.015 314.232 203.811C209.726 223.602 126.482 273.884 91.5481 338.319C71.7637 374.808 69.2153 413.332 84.1772 449.727C126.822 553.441 299.508 611.69 469.134 579.568C573.64 559.777 656.884 509.495 691.818 445.06C711.603 408.571 714.151 370.047 699.189 333.652C674.859 274.473 608.169 230.084 523.88 208.857Z"
          stroke="url(#paint56_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M259.146 576.338C174.209 554.948 106.993 510.174 82.4303 450.449C67.2416 413.517 69.8227 374.429 89.889 337.421C125.091 272.491 208.829 221.853 313.883 201.956C484.34 169.673 657.976 228.43 700.944 332.933C716.133 369.864 713.551 408.952 693.485 445.96C658.283 510.891 574.545 561.529 469.491 581.425C396.454 595.255 322.82 592.373 259.146 576.338ZM523.88 208.857C460.452 192.884 387.068 190.015 314.232 203.811C209.726 223.602 126.482 273.884 91.5481 338.319C71.7637 374.808 69.2153 413.332 84.1772 449.727C126.822 553.441 299.508 611.69 469.134 579.568C573.64 559.777 656.884 509.495 691.818 445.06C711.603 408.571 714.151 370.047 699.189 333.652C674.859 274.473 608.169 230.084 523.88 208.857Z"
          stroke="url(#paint57_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M259.146 576.338C174.209 554.948 106.993 510.174 82.4303 450.449C67.2416 413.517 69.8227 374.429 89.889 337.421C125.091 272.491 208.829 221.853 313.883 201.956C484.34 169.673 657.976 228.43 700.944 332.933C716.133 369.864 713.551 408.952 693.485 445.96C658.283 510.891 574.545 561.529 469.491 581.425C396.454 595.255 322.82 592.373 259.146 576.338ZM523.88 208.857C460.452 192.884 387.068 190.015 314.232 203.811C209.726 223.602 126.482 273.884 91.5481 338.319C71.7637 374.808 69.2153 413.332 84.1772 449.727C126.822 553.441 299.508 611.69 469.134 579.568C573.64 559.777 656.884 509.495 691.818 445.06C711.603 408.571 714.151 370.047 699.189 333.652C674.859 274.473 608.169 230.084 523.88 208.857Z"
          stroke="url(#paint58_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M259.146 576.338C174.209 554.948 106.993 510.174 82.4303 450.449C67.2416 413.517 69.8227 374.429 89.889 337.421C125.091 272.491 208.829 221.853 313.883 201.956C484.34 169.673 657.976 228.43 700.944 332.933C716.133 369.864 713.551 408.952 693.485 445.96C658.283 510.891 574.545 561.529 469.491 581.425C396.454 595.255 322.82 592.373 259.146 576.338ZM523.88 208.857C460.452 192.884 387.068 190.015 314.232 203.811C209.726 223.602 126.482 273.884 91.5481 338.319C71.7637 374.808 69.2153 413.332 84.1772 449.727C126.822 553.441 299.508 611.69 469.134 579.568C573.64 559.777 656.884 509.495 691.818 445.06C711.603 408.571 714.151 370.047 699.189 333.652C674.859 274.473 608.169 230.084 523.88 208.857Z"
          stroke="url(#paint59_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M276.506 592.415C175.741 567.04 96.9388 508.155 76.22 432.23C64.5545 389.495 72.4102 345.903 98.9257 306.178C142.243 241.271 230.269 194.557 334.396 181.218C508.299 158.942 675.521 235.179 707.168 351.154C718.833 393.889 710.978 437.481 684.462 477.206C641.145 542.113 553.119 588.827 448.992 602.166C388.938 609.857 329.664 605.794 276.506 592.415ZM506.515 192.795C453.565 179.46 394.496 175.416 334.624 183.085C231.036 196.358 143.509 242.759 100.49 307.219C74.2856 346.477 66.5219 389.536 78.0378 431.729C109.45 546.828 275.743 622.444 448.742 600.285C552.331 587.012 639.865 540.613 682.877 476.151C709.081 436.893 716.844 393.834 705.328 351.642C684.789 276.376 606.567 217.991 506.507 192.793L506.515 192.795Z"
          stroke="url(#paint60_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M276.506 592.415C175.741 567.04 96.9388 508.155 76.22 432.23C64.5545 389.495 72.4102 345.903 98.9257 306.178C142.243 241.271 230.269 194.557 334.396 181.218C508.299 158.942 675.521 235.179 707.168 351.154C718.833 393.889 710.978 437.481 684.462 477.206C641.145 542.113 553.119 588.827 448.992 602.166C388.938 609.857 329.664 605.794 276.506 592.415ZM506.515 192.795C453.565 179.46 394.496 175.416 334.624 183.085C231.036 196.358 143.509 242.759 100.49 307.219C74.2856 346.477 66.5219 389.536 78.0378 431.729C109.45 546.828 275.743 622.444 448.742 600.285C552.331 587.012 639.865 540.613 682.877 476.151C709.081 436.893 716.844 393.834 705.328 351.642C684.789 276.376 606.567 217.991 506.507 192.793L506.515 192.795Z"
          stroke="url(#paint61_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M276.506 592.415C175.741 567.04 96.9388 508.155 76.22 432.23C64.5545 389.495 72.4102 345.903 98.9257 306.178C142.243 241.271 230.269 194.557 334.396 181.218C508.299 158.942 675.521 235.179 707.168 351.154C718.833 393.889 710.978 437.481 684.462 477.206C641.145 542.113 553.119 588.827 448.992 602.166C388.938 609.857 329.664 605.794 276.506 592.415ZM506.515 192.795C453.565 179.46 394.496 175.416 334.624 183.085C231.036 196.358 143.509 242.759 100.49 307.219C74.2856 346.477 66.5219 389.536 78.0378 431.729C109.45 546.828 275.743 622.444 448.742 600.285C552.331 587.012 639.865 540.613 682.877 476.151C709.081 436.893 716.844 393.834 705.328 351.642C684.789 276.376 606.567 217.991 506.507 192.793L506.515 192.795Z"
          stroke="url(#paint62_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M276.506 592.415C175.741 567.04 96.9388 508.155 76.22 432.23C64.5545 389.495 72.4102 345.903 98.9257 306.178C142.243 241.271 230.269 194.557 334.396 181.218C508.299 158.942 675.521 235.179 707.168 351.154C718.833 393.889 710.978 437.481 684.462 477.206C641.145 542.113 553.119 588.827 448.992 602.166C388.938 609.857 329.664 605.794 276.506 592.415ZM506.515 192.795C453.565 179.46 394.496 175.416 334.624 183.085C231.036 196.358 143.509 242.759 100.49 307.219C74.2856 346.477 66.5219 389.536 78.0378 431.729C109.45 546.828 275.743 622.444 448.742 600.285C552.331 587.012 639.865 540.613 682.877 476.151C709.081 436.893 716.844 393.834 705.328 351.642C684.789 276.376 606.567 217.991 506.507 192.793L506.515 192.795Z"
          stroke="url(#paint63_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M291.689 610.924C175.39 581.636 85.9906 505.751 71.9764 412.125C64.57 362.704 78.7362 314.204 112.918 271.873C164.084 208.514 255.373 166.821 357.133 160.342C533.385 149.127 692.31 243.741 711.406 371.257C718.806 420.668 704.648 469.17 670.458 511.499C619.292 574.858 528.003 616.551 426.243 623.03C379.401 626.011 333.776 621.514 291.683 610.914L291.689 610.924ZM491.305 174.296C449.371 163.736 403.935 159.261 357.246 162.231C256.009 168.673 165.224 210.105 114.386 273.059C80.5459 314.972 66.5172 362.959 73.8414 411.837C87.7441 504.703 176.539 579.982 292.069 609.084C334.003 619.644 379.439 624.119 426.128 621.149C527.365 614.707 618.15 573.275 668.988 510.321C702.828 468.408 716.849 420.419 709.533 371.543C695.63 278.677 606.837 203.391 491.305 174.296Z"
          stroke="url(#paint64_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M291.689 610.924C175.39 581.636 85.9906 505.751 71.9764 412.125C64.57 362.704 78.7362 314.204 112.918 271.873C164.084 208.514 255.373 166.821 357.133 160.342C533.385 149.127 692.31 243.741 711.406 371.257C718.806 420.668 704.648 469.17 670.458 511.499C619.292 574.858 528.003 616.551 426.243 623.03C379.401 626.011 333.776 621.514 291.683 610.914L291.689 610.924ZM491.305 174.296C449.371 163.736 403.935 159.261 357.246 162.231C256.009 168.673 165.224 210.105 114.386 273.059C80.5459 314.972 66.5172 362.959 73.8414 411.837C87.7441 504.703 176.539 579.982 292.069 609.084C334.003 619.644 379.439 624.119 426.128 621.149C527.365 614.707 618.15 573.275 668.988 510.321C702.828 468.408 716.849 420.419 709.533 371.543C695.63 278.677 606.837 203.391 491.305 174.296Z"
          stroke="url(#paint65_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M291.689 610.924C175.39 581.636 85.9906 505.751 71.9764 412.125C64.57 362.704 78.7362 314.204 112.918 271.873C164.084 208.514 255.373 166.821 357.133 160.342C533.385 149.127 692.31 243.741 711.406 371.257C718.806 420.668 704.648 469.17 670.458 511.499C619.292 574.858 528.003 616.551 426.243 623.03C379.401 626.011 333.776 621.514 291.683 610.914L291.689 610.924ZM491.305 174.296C449.371 163.736 403.935 159.261 357.246 162.231C256.009 168.673 165.224 210.105 114.386 273.059C80.5459 314.972 66.5172 362.959 73.8414 411.837C87.7441 504.703 176.539 579.982 292.069 609.084C334.003 619.644 379.439 624.119 426.128 621.149C527.365 614.707 618.15 573.275 668.988 510.321C702.828 468.408 716.849 420.419 709.533 371.543C695.63 278.677 606.837 203.391 491.305 174.296Z"
          stroke="url(#paint66_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M291.689 610.924C175.39 581.636 85.9906 505.751 71.9764 412.125C64.57 362.704 78.7362 314.204 112.918 271.873C164.084 208.514 255.373 166.821 357.133 160.342C533.385 149.127 692.31 243.741 711.406 371.257C718.806 420.668 704.648 469.17 670.458 511.499C619.292 574.858 528.003 616.551 426.243 623.03C379.401 626.011 333.776 621.514 291.683 610.914L291.689 610.924ZM491.305 174.296C449.371 163.736 403.935 159.261 357.246 162.231C256.009 168.673 165.224 210.105 114.386 273.059C80.5459 314.972 66.5172 362.959 73.8414 411.837C87.7441 504.703 176.539 579.982 292.069 609.084C334.003 619.644 379.439 624.119 426.128 621.149C527.365 614.707 618.15 573.275 668.988 510.321C702.828 468.408 716.849 420.419 709.533 371.543C695.63 278.677 606.837 203.391 491.305 174.296Z"
          stroke="url(#paint67_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M303.512 631.616C172.272 598.566 74.198 502.588 69.9127 390.112C67.7407 333.105 89.3618 279.431 132.442 234.914C191.591 173.776 282.603 138.998 382.126 139.496C559.536 140.388 708.181 254.235 713.478 393.272C715.65 450.279 694.031 503.945 650.948 548.47C591.799 609.609 500.788 644.386 401.257 643.886C367.368 643.717 334.502 639.42 303.504 631.614L303.512 631.616ZM479.45 153.604C448.579 145.83 415.885 141.552 382.113 141.387C283.094 140.889 192.59 175.458 133.796 236.223C91.0861 280.37 69.648 333.552 71.7991 390.04C76.0482 501.657 173.518 596.927 303.933 629.778C334.804 637.552 367.498 641.831 401.27 641.996C500.288 642.493 590.793 607.924 649.587 547.159C692.297 503.012 713.735 449.83 711.582 393.35C707.332 281.733 609.865 186.455 479.448 153.612L479.45 153.604Z"
          stroke="url(#paint68_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M303.512 631.616C172.272 598.566 74.198 502.588 69.9127 390.112C67.7407 333.105 89.3618 279.431 132.442 234.914C191.591 173.776 282.603 138.998 382.126 139.496C559.536 140.388 708.181 254.235 713.478 393.272C715.65 450.279 694.031 503.945 650.948 548.47C591.799 609.609 500.788 644.386 401.257 643.886C367.368 643.717 334.502 639.42 303.504 631.614L303.512 631.616ZM479.45 153.604C448.579 145.83 415.885 141.552 382.113 141.387C283.094 140.889 192.59 175.458 133.796 236.223C91.0861 280.37 69.648 333.552 71.7991 390.04C76.0482 501.657 173.518 596.927 303.933 629.778C334.804 637.552 367.498 641.831 401.27 641.996C500.288 642.493 590.793 607.924 649.587 547.159C692.297 503.012 713.735 449.83 711.582 393.35C707.332 281.733 609.865 186.455 479.448 153.612L479.45 153.604Z"
          stroke="url(#paint69_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M303.512 631.616C172.272 598.566 74.198 502.588 69.9127 390.112C67.7407 333.105 89.3618 279.431 132.442 234.914C191.591 173.776 282.603 138.998 382.126 139.496C559.536 140.388 708.181 254.235 713.478 393.272C715.65 450.279 694.031 503.945 650.948 548.47C591.799 609.609 500.788 644.386 401.257 643.886C367.368 643.717 334.502 639.42 303.504 631.614L303.512 631.616ZM479.45 153.604C448.579 145.83 415.885 141.552 382.113 141.387C283.094 140.889 192.59 175.458 133.796 236.223C91.0861 280.37 69.648 333.552 71.7991 390.04C76.0482 501.657 173.518 596.927 303.933 629.778C334.804 637.552 367.498 641.831 401.27 641.996C500.288 642.493 590.793 607.924 649.587 547.159C692.297 503.012 713.735 449.83 711.582 393.35C707.332 281.733 609.865 186.455 479.448 153.612L479.45 153.604Z"
          stroke="url(#paint70_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M303.512 631.616C172.272 598.566 74.198 502.588 69.9127 390.112C67.7407 333.105 89.3618 279.431 132.442 234.914C191.591 173.776 282.603 138.998 382.126 139.496C559.536 140.388 708.181 254.235 713.478 393.272C715.65 450.279 694.031 503.945 650.948 548.47C591.799 609.609 500.788 644.386 401.257 643.886C367.368 643.717 334.502 639.42 303.504 631.614L303.512 631.616ZM479.45 153.604C448.579 145.83 415.885 141.552 382.113 141.387C283.094 140.889 192.59 175.458 133.796 236.223C91.0861 280.37 69.648 333.552 71.7991 390.04C76.0482 501.657 173.518 596.927 303.933 629.778C334.804 637.552 367.498 641.831 401.27 641.996C500.288 642.493 590.793 607.924 649.587 547.159C692.297 503.012 713.735 449.83 711.582 393.35C707.332 281.733 609.865 186.455 479.448 153.612L479.45 153.604Z"
          stroke="url(#paint71_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M311.2 654.255C165.699 617.613 61.6725 498.323 70.225 366.215C74.4017 301.643 105.507 241.151 157.818 195.87C223.046 139.401 314.734 111.324 409.372 118.823C586.626 132.87 722.905 266.712 713.167 417.162C708.991 481.734 677.885 542.227 625.584 587.501C560.357 643.97 468.668 672.047 374.03 664.548C352.429 662.837 331.413 659.337 311.218 654.251L311.2 654.255ZM471.73 130.958C451.622 125.894 430.726 122.416 409.217 120.711C315.083 113.247 223.906 141.166 159.057 197.301C107.132 242.25 76.2532 302.278 72.113 366.337C63.6254 497.519 167.04 615.998 311.654 652.417C331.763 657.481 352.658 660.959 374.168 662.664C468.301 670.128 559.487 642.211 624.328 586.074C676.242 541.131 707.121 481.104 711.264 417.036C719.751 285.854 616.336 167.375 471.722 130.956L471.73 130.958Z"
          stroke="url(#paint72_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M311.2 654.255C165.699 617.613 61.6725 498.323 70.225 366.215C74.4017 301.643 105.507 241.151 157.818 195.87C223.046 139.401 314.734 111.324 409.372 118.823C586.626 132.87 722.905 266.712 713.167 417.162C708.991 481.734 677.885 542.227 625.584 587.501C560.357 643.97 468.668 672.047 374.03 664.548C352.429 662.837 331.413 659.337 311.218 654.251L311.2 654.255ZM471.73 130.958C451.622 125.894 430.726 122.416 409.217 120.711C315.083 113.247 223.906 141.166 159.057 197.301C107.132 242.25 76.2532 302.278 72.113 366.337C63.6254 497.519 167.04 615.998 311.654 652.417C331.763 657.481 352.658 660.959 374.168 662.664C468.301 670.128 559.487 642.211 624.328 586.074C676.242 541.131 707.121 481.104 711.264 417.036C719.751 285.854 616.336 167.375 471.722 130.956L471.73 130.958Z"
          stroke="url(#paint73_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M311.2 654.255C165.699 617.613 61.6725 498.323 70.225 366.215C74.4017 301.643 105.507 241.151 157.818 195.87C223.046 139.401 314.734 111.324 409.372 118.823C586.626 132.87 722.905 266.712 713.167 417.162C708.991 481.734 677.885 542.227 625.584 587.501C560.357 643.97 468.668 672.047 374.03 664.548C352.429 662.837 331.413 659.337 311.218 654.251L311.2 654.255ZM471.73 130.958C451.622 125.894 430.726 122.416 409.217 120.711C315.083 113.247 223.906 141.166 159.057 197.301C107.132 242.25 76.2532 302.278 72.113 366.337C63.6254 497.519 167.04 615.998 311.654 652.417C331.763 657.481 352.658 660.959 374.168 662.664C468.301 670.128 559.487 642.211 624.328 586.074C676.242 541.131 707.121 481.104 711.264 417.036C719.751 285.854 616.336 167.375 471.722 130.956L471.73 130.958Z"
          stroke="url(#paint74_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M311.2 654.255C165.699 617.613 61.6725 498.323 70.225 366.215C74.4017 301.643 105.507 241.151 157.818 195.87C223.046 139.401 314.734 111.324 409.372 118.823C586.626 132.87 722.905 266.712 713.167 417.162C708.991 481.734 677.885 542.227 625.584 587.501C560.357 643.97 468.668 672.047 374.03 664.548C352.429 662.837 331.413 659.337 311.218 654.251L311.2 654.255ZM471.73 130.958C451.622 125.894 430.726 122.416 409.217 120.711C315.083 113.247 223.906 141.166 159.057 197.301C107.132 242.25 76.2532 302.278 72.113 366.337C63.6254 497.519 167.04 615.998 311.654 652.417C331.763 657.481 352.658 660.959 374.168 662.664C468.301 670.128 559.487 642.211 624.328 586.074C676.242 541.131 707.121 481.104 711.264 417.036C719.751 285.854 616.336 167.375 471.722 130.956L471.73 130.958Z"
          stroke="url(#paint75_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M314.367 678.642C155.164 638.566 48.6414 492.694 73.1162 340.469C99.1155 178.815 263.175 70.2835 438.83 98.5296C523.907 112.213 598.997 155.561 650.259 220.58C701.547 285.63 722.848 364.588 710.252 442.912C697.657 521.228 652.67 589.526 583.581 635.227C514.515 680.909 429.625 698.528 344.548 684.846C334.308 683.201 324.221 681.115 314.369 678.634L314.367 678.642ZM468.875 106.643C458.92 104.136 448.799 102.05 438.528 100.398C263.899 72.3175 100.817 180.144 74.9861 340.763C50.6674 492.001 156.598 636.95 314.829 676.806C324.642 679.277 334.642 681.341 344.848 682.986C429.457 696.592 513.867 679.071 582.539 633.652C651.181 588.251 695.877 520.409 708.39 442.62C720.894 364.828 699.731 286.393 648.775 221.763C603.995 164.958 540.911 124.792 468.873 106.651L468.875 106.643Z"
          stroke="url(#paint76_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M314.367 678.642C155.164 638.566 48.6414 492.694 73.1162 340.469C99.1155 178.815 263.175 70.2835 438.83 98.5296C523.907 112.213 598.997 155.561 650.259 220.58C701.547 285.63 722.848 364.588 710.252 442.912C697.657 521.228 652.67 589.526 583.581 635.227C514.515 680.909 429.625 698.528 344.548 684.846C334.308 683.201 324.221 681.115 314.369 678.634L314.367 678.642ZM468.875 106.643C458.92 104.136 448.799 102.05 438.528 100.398C263.899 72.3175 100.817 180.144 74.9861 340.763C50.6674 492.001 156.598 636.95 314.829 676.806C324.642 679.277 334.642 681.341 344.848 682.986C429.457 696.592 513.867 679.071 582.539 633.652C651.181 588.251 695.877 520.409 708.39 442.62C720.894 364.828 699.731 286.393 648.775 221.763C603.995 164.958 540.911 124.792 468.873 106.651L468.875 106.643Z"
          stroke="url(#paint77_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M314.367 678.642C155.164 638.566 48.6414 492.694 73.1162 340.469C99.1155 178.815 263.175 70.2835 438.83 98.5296C523.907 112.213 598.997 155.561 650.259 220.58C701.547 285.63 722.848 364.588 710.252 442.912C697.657 521.228 652.67 589.526 583.581 635.227C514.515 680.909 429.625 698.528 344.548 684.846C334.308 683.201 324.221 681.115 314.369 678.634L314.367 678.642ZM468.875 106.643C458.92 104.136 448.799 102.05 438.528 100.398C263.899 72.3175 100.817 180.144 74.9861 340.763C50.6674 492.001 156.598 636.95 314.829 676.806C324.642 679.277 334.642 681.341 344.848 682.986C429.457 696.592 513.867 679.071 582.539 633.652C651.181 588.251 695.877 520.409 708.39 442.62C720.894 364.828 699.731 286.393 648.775 221.763C603.995 164.958 540.911 124.792 468.873 106.651L468.875 106.643Z"
          stroke="url(#paint78_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M314.367 678.642C155.164 638.566 48.6414 492.694 73.1162 340.469C99.1155 178.815 263.175 70.2835 438.83 98.5296C523.907 112.213 598.997 155.561 650.259 220.58C701.547 285.63 722.848 364.588 710.252 442.912C697.657 521.228 652.67 589.526 583.581 635.227C514.515 680.909 429.625 698.528 344.548 684.846C334.308 683.201 324.221 681.115 314.369 678.634L314.367 678.642ZM468.875 106.643C458.92 104.136 448.799 102.05 438.528 100.398C263.899 72.3175 100.817 180.144 74.9861 340.763C50.6674 492.001 156.598 636.95 314.829 676.806C324.642 679.277 334.642 681.341 344.848 682.986C429.457 696.592 513.867 679.071 582.539 633.652C651.181 588.251 695.877 520.409 708.39 442.62C720.894 364.828 699.731 286.393 648.775 221.763C603.995 164.958 540.911 124.792 468.873 106.651L468.875 106.643Z"
          stroke="url(#paint79_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M312.89 704.584C140.365 661.137 35.3485 485.424 78.798 312.89C122.247 140.357 297.958 35.3483 470.484 78.7958C643.01 122.243 748.026 297.956 704.578 470.482C661.131 643.007 485.418 748.024 312.892 704.576L312.89 704.584ZM470.022 80.6317C298.509 37.4394 123.826 141.84 80.6339 313.353C37.4416 484.865 141.84 659.556 313.353 702.748C484.866 745.941 659.556 641.542 702.75 470.021C745.945 298.501 641.542 123.826 470.022 80.6317Z"
          stroke="url(#paint80_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M312.89 704.584C140.365 661.137 35.3485 485.424 78.798 312.89C122.247 140.357 297.958 35.3483 470.484 78.7958C643.01 122.243 748.026 297.956 704.578 470.482C661.131 643.007 485.418 748.024 312.892 704.576L312.89 704.584ZM470.022 80.6317C298.509 37.4394 123.826 141.84 80.6339 313.353C37.4416 484.865 141.84 659.556 313.353 702.748C484.866 745.941 659.556 641.542 702.75 470.021C745.945 298.501 641.542 123.826 470.022 80.6317Z"
          stroke="url(#paint81_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M312.89 704.584C140.365 661.137 35.3485 485.424 78.798 312.89C122.247 140.357 297.958 35.3483 470.484 78.7958C643.01 122.243 748.026 297.956 704.578 470.482C661.131 643.007 485.418 748.024 312.892 704.576L312.89 704.584ZM470.022 80.6317C298.509 37.4394 123.826 141.84 80.6339 313.353C37.4416 484.865 141.84 659.556 313.353 702.748C484.866 745.941 659.556 641.542 702.75 470.021C745.945 298.501 641.542 123.826 470.022 80.6317Z"
          stroke="url(#paint82_radial_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
        <path
          d="M312.89 704.584C140.365 661.137 35.3485 485.424 78.798 312.89C122.247 140.357 297.958 35.3483 470.484 78.7958C643.01 122.243 748.026 297.956 704.578 470.482C661.131 643.007 485.418 748.024 312.892 704.576L312.89 704.584ZM470.022 80.6317C298.509 37.4394 123.826 141.84 80.6339 313.353C37.4416 484.865 141.84 659.556 313.353 702.748C484.866 745.941 659.556 641.542 702.75 470.021C745.945 298.501 641.542 123.826 470.022 80.6317Z"
          stroke="url(#paint83_linear_2308_33949)"
          strokeWidth="1.63215"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2308_33949"
        x1="468.075"
        y1="589.949"
        x2="333.009"
        y2="247.988"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.688 391.686) rotate(104.135) scale(180.998 173.398)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(333.958 557.101) rotate(-73.7388) scale(165.591 439.248)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_2308_33949"
        x1="488.972"
        y1="315.568"
        x2="443.966"
        y2="393.403"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2308_33949"
        x1="475.616"
        y1="594.294"
        x2="345.071"
        y2="238.302"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.69 391.692) rotate(104.135) scale(183.465 185.248)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(332.345 559.154) rotate(-73.6241) scale(167.861 469.229)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_2308_33949"
        x1="494.318"
        y1="315.549"
        x2="449.604"
        y2="395.355"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2308_33949"
        x1="483.354"
        y1="598.1"
        x2="358.187"
        y2="229.541"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint9_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.688 391.69) rotate(104.135) scale(185.344 197.189)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(330.86 560.647) rotate(-73.504) scale(169.594 499.435)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_2308_33949"
        x1="499.622"
        y1="315.839"
        x2="455.342"
        y2="397.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2308_33949"
        x1="491.196"
        y1="601.494"
        x2="371.916"
        y2="221.792"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.692 391.684) rotate(104.135) scale(186.787 209.124)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(329.484 561.727) rotate(-73.3806) scale(170.93 529.614)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint15_linear_2308_33949"
        x1="504.872"
        y1="316.351"
        x2="461.105"
        y2="399.238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2308_33949"
        x1="499.109"
        y1="604.26"
        x2="386.397"
        y2="215.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.693 391.686) rotate(104.135) scale(187.567 220.941)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(328.271 562.199) rotate(-73.2513) scale(171.661 559.485)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint19_linear_2308_33949"
        x1="509.981"
        y1="317.207"
        x2="466.869"
        y2="401.164"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_2308_33949"
        x1="506.836"
        y1="606.571"
        x2="400.799"
        y2="209.799"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.688 391.689) rotate(104.135) scale(187.931 232.354)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(327.185 562.292) rotate(-73.1218) scale(172.013 588.324)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_2308_33949"
        x1="514.859"
        y1="318.237"
        x2="472.442"
        y2="403.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_2308_33949"
        x1="514.546"
        y1="608.61"
        x2="415.237"
        y2="205.325"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.683 391.69) rotate(104.135) scale(188.028 243.648)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(326.174 562.135) rotate(-72.9902) scale(172.12 616.851)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_2308_33949"
        x1="519.652"
        y1="319.392"
        x2="477.948"
        y2="404.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_2308_33949"
        x1="521.986"
        y1="610.491"
        x2="429.017"
        y2="201.706"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.683 391.686) rotate(104.135) scale(188.039 254.513)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(325.224 561.903) rotate(-72.8624) scale(172.151 644.284)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_2308_33949"
        x1="524.256"
        y1="320.541"
        x2="483.214"
        y2="406.448"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_2308_33949"
        x1="529.026"
        y1="612.409"
        x2="441.701"
        y2="198.723"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint33_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.681 391.695) rotate(104.135) scale(188.176 264.838)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(324.29 561.814) rotate(-72.7431) scale(172.295 670.348)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_2308_33949"
        x1="528.646"
        y1="321.58"
        x2="488.155"
        y2="407.98"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_2308_33949"
        x1="535.543"
        y1="614.592"
        x2="452.811"
        y2="196.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint37_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.686 391.693) rotate(104.135) scale(188.727 274.534)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(323.32 562.114) rotate(-72.6384) scale(172.817 694.819)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint39_linear_2308_33949"
        x1="532.831"
        y1="322.325"
        x2="492.702"
        y2="409.323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_2308_33949"
        x1="541.386"
        y1="617.354"
        x2="461.797"
        y2="193.166"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint41_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.696 391.688) rotate(104.135) scale(190.039 283.497)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(322.24 563.14) rotate(-72.5563) scale(174.033 717.445)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint43_linear_2308_33949"
        x1="536.811"
        y1="322.59"
        x2="496.766"
        y2="410.449"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_2308_33949"
        x1="546.382"
        y1="621.083"
        x2="468.039"
        y2="189.726"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint45_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.686 391.692) rotate(104.135) scale(192.531 291.657)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint46_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(320.932 565.294) rotate(-72.5052) scale(176.324 738.058)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint47_linear_2308_33949"
        x1="540.586"
        y1="322.161"
        x2="500.236"
        y2="411.325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_2308_33949"
        x1="550.419"
        y1="626.159"
        x2="470.936"
        y2="185.213"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint49_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.697 391.684) rotate(104.135) scale(196.646 298.941)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint50_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(319.339 568.973) rotate(-72.4934) scale(180.094 756.479)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint51_linear_2308_33949"
        x1="544.224"
        y1="320.78"
        x2="503.063"
        y2="411.884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_2308_33949"
        x1="553.297"
        y1="632.993"
        x2="469.76"
        y2="179.303"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint53_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.688 391.691) rotate(104.135) scale(202.814 305.265)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint54_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(317.326 574.607) rotate(-72.5267) scale(185.737 772.511)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint55_linear_2308_33949"
        x1="547.706"
        y1="318.237"
        x2="505.104"
        y2="412.095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_2308_33949"
        x1="554.932"
        y1="641.922"
        x2="463.869"
        y2="171.809"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint57_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.687 391.69) rotate(104.135) scale(211.425 310.598)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint58_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(314.833 582.541) rotate(-72.6066) scale(193.608 786.068)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint59_linear_2308_33949"
        x1="551.098"
        y1="314.303"
        x2="506.301"
        y2="411.877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_2308_33949"
        x1="555.234"
        y1="653.226"
        x2="452.613"
        y2="162.886"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint61_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.694 391.693) rotate(104.135) scale(222.775 314.91)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint62_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(311.791 593.06) rotate(-72.7294) scale(203.977 797.077)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint63_linear_2308_33949"
        x1="554.425"
        y1="308.833"
        x2="506.565"
        y2="411.174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_2308_33949"
        x1="554.154"
        y1="667.039"
        x2="435.476"
        y2="153.032"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint65_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.689 391.69) rotate(104.135) scale(237.017 318.21)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint66_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(308.146 606.3) rotate(-72.8866) scale(216.985 805.546)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint67_linear_2308_33949"
        x1="557.693"
        y1="301.738"
        x2="505.807"
        y2="409.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_2308_33949"
        x1="551.722"
        y1="683.417"
        x2="412.125"
        y2="143.102"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint69_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.695 391.692) rotate(104.135) scale(254.194 320.524)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint70_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(303.906 622.312) rotate(-73.0674) scale(232.673 811.534)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint71_linear_2308_33949"
        x1="560.941"
        y1="293.007"
        x2="503.987"
        y2="407.998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint72_linear_2308_33949"
        x1="547.885"
        y1="702.308"
        x2="382.243"
        y2="134.258"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint73_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.697 391.686) rotate(104.135) scale(274.277 321.787)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint74_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(299.069 641.053) rotate(-73.2617) scale(251.015 814.863)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint75_linear_2308_33949"
        x1="564.121"
        y1="282.638"
        x2="500.983"
        y2="405.327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint76_linear_2308_33949"
        x1="543.002"
        y1="723.705"
        x2="346.537"
        y2="127.591"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint77_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.692 391.684) rotate(104.135) scale(297.153 322.489)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint78_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(293.617 662.424) rotate(-73.4567) scale(271.911 816.761)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint79_linear_2308_33949"
        x1="567.425"
        y1="270.753"
        x2="496.969"
        y2="401.868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint80_linear_2308_33949"
        x1="537.139"
        y1="747.468"
        x2="305.442"
        y2="124.349"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint81_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(391.687 391.69) rotate(104.135) scale(322.663 322.659)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint82_radial_2308_33949"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(287.591 686.277) rotate(-73.6457) scale(295.215 817.302)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint83_linear_2308_33949"
        x1="570.851"
        y1="257.441"
        x2="491.921"
        y2="397.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2308_33949">
        <rect
          width="645.327"
          height="645.327"
          fill="white"
          transform="translate(157.594) rotate(14.135)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HomeMainBg;
