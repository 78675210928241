// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// store

// components

import AppButton from "../../../components/atoms/Button";
import WeldCardImg from "./../../../assets/images/weld-card.png";
import WeldCardSub1Img from "./../../../assets/images/weld-card-sub-1.png";
import WeldCardSub2Img from "./../../../assets/images/weld-card-sub-2.png";
import WeldCardSub2MobImg from "./../../../assets/images/weld-card-sub-2-mob.png";
import WeldBannerArrowDown from "../../../components/icons/WeldBannerArrowDown";
import WeldBannerArrowDownMob from "../../../components/icons/WeldBannerArrowDownMob";
import WeldBannerArrowUp from "../../../components/icons/WeldBannerArrowUp";
import WeldBannerArrowUpMob from "../../../components/icons/WeldBannerArrowUpMob";

// styles
import "./index.scss";

const WeldBanner = ({ data }) => {
  // store
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="weld-banner">
      <div className="app__wrapper">
        <div className="weld-banner__grid">
          <div
            className="weld-banner__content"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <div className="weld__title">{data.title}</div>
            {data.warning && (
              <div
                className="weld-banner__note"
                dangerouslySetInnerHTML={{ __html: data.warning }}
              />
            )}

            {data.blue_description_part_1 && (
              <div className="weld-banner__subtitle weld-banner__subtitle--1">
                {data.blue_description_part_1}
              </div>
            )}
            {data.blue_description_part_2 && width > 1024 && (
              <div className="weld-banner__subtitle weld-banner__subtitle--2">
                {data.blue_description_part_2}
              </div>
            )}

            {width > 1024 && (
              <>
                <div
                  className="weld-banner__text"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />
                <div className="weld-banner__actions">
                  <AppButton
                    className="app-button--fill-3 weld__btn"
                    href={data.url}
                    target="_blank"
                  >
                    {t("actions.installApp")}
                  </AppButton>
                </div>
              </>
            )}
          </div>
          <div className="weld-banner__imgs">
            <img
              src={WeldCardSub1Img}
              className="weld-banner__subimg-1"
              data-aos="fade-up"
              data-aos-delay="1800"
            />
            {width > 1024 ? (
              <WeldBannerArrowDown
                className="weld-banner__arrow-down"
                data-aos="fade-up"
                data-aos-delay="1600"
              />
            ) : (
              <WeldBannerArrowDownMob
                className="weld-banner__arrow-down"
                data-aos="fade-up"
                data-aos-delay="1600"
              />
            )}
            <img
              src={WeldCardImg}
              className="weld-banner__img"
              data-aos="fade-up"
              data-aos-delay="1400"
            />
            <img
              src={width > 1024 ? WeldCardSub2Img : WeldCardSub2MobImg}
              className="weld-banner__subimg-2"
              data-aos="fade-up"
              data-aos-delay="1800"
            />
            {width > 1024 ? (
              <WeldBannerArrowUp
                className="weld-banner__arrow-up"
                data-aos="fade-up"
                data-aos-delay="1600"
              />
            ) : (
              <WeldBannerArrowUpMob
                className="weld-banner__arrow-up"
                data-aos="fade-up"
                data-aos-delay="1600"
              />
            )}
          </div>

          {width <= 1024 && (
            <div
              className="weld-banner__content"
              data-aos="fade-up"
              data-aos-delay="1600"
            >
              {data.blue_description_part_2 && (
                <div className="weld-banner__subtitle weld-banner__subtitle--2">
                  {data.blue_description_part_2}
                </div>
              )}
              <div
                className="weld-banner__text"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
              <div className="weld-banner__actions">
                <AppButton
                  className="app-button--fill-3 weld__btn"
                  href={data.url}
                  target="_blank"
                >
                  {t("actions.installApp")}
                </AppButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldBanner;
