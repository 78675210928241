const QuestionBox = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="2.30816" fill="#0790F1" />
    <path
      d="M14.907 16.35C15.677 14.96 17.157 14.14 18.017 12.91C18.927 11.62 18.417 9.21 15.837 9.21C14.147 9.21 13.317 10.49 12.967 11.55L10.377 10.46C11.087 8.33 13.017 6.5 15.827 6.5C18.177 6.5 19.787 7.57 20.607 8.91C21.307 10.06 21.717 12.21 20.637 13.81C19.437 15.58 18.287 16.12 17.667 17.26C17.417 17.72 17.317 18.02 17.317 19.5H14.427C14.417 18.72 14.297 17.45 14.907 16.35ZM17.837 23.5C17.837 24.6 16.937 25.5 15.837 25.5C14.737 25.5 13.837 24.6 13.837 23.5C13.837 22.4 14.737 21.5 15.837 21.5C16.937 21.5 17.837 22.4 17.837 23.5Z"
      fill="white"
    />
  </svg>
);

export default QuestionBox;
