// core
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { ErrorMessage, Formik, Field, Form } from "formik";
import * as Yup from "yup";
// store

// components
import AppButton from "../../../components/atoms/Button";
import WeldCTABg from "../../../components/icons/WeldCTABg";

// styles
import "./index.scss";

// data
import { INITIAL_DATA } from "../HomeWelcome/data";

const HomeCTA = ({ data }) => {
  // store

  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const formikRef = useRef();
  const CURRENCY_REGEX = /^\d+(\.\d{1,2})?$/gm;
  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  const FormValidation = Yup.object().shape({
    amount: Yup.string()
      .matches(CURRENCY_REGEX, {
        message: t("validation.isCurrency"),
      })
      .required(t("validation.required")),
  });

  const onSendHandler = () => {
    window.open(`https://whiteex.com/${localization}/#mainForm`, "_blank");
  };
  return (
    <div className="home-cta">
      <div className="app__wrapper">
        <div className="home-cta__grid">
          <div className="home-cta__content">
            <div className="home-cta__title">{data.title}</div>
            <div className="home-cta__text">{data.text}</div>
            {width > 768 && (
              <div className="home-cta__actions">
                <Formik
                  enableReinitialize={true}
                  initialValues={INITIAL_DATA}
                  onSubmit={onSendHandler}
                  validationSchema={FormValidation}
                  innerRef={formikRef}
                >
                  {({ errors, touched }) => (
                    <Form className="app-form">
                      <div className="app-form__field app-form__field--input">
                        <label>{t("landing.inputSumLabel")}</label>
                        <div className="app-form__group">
                          <Field
                            type="text"
                            name="amount"
                            placeholder={"1 000 UAH"}
                            autoComplete="chrome-off"
                            className={
                              "home-cta__input" +
                              (errors.amount && touched.amount
                                ? " app-form__field--error"
                                : "")
                            }
                          />
                          <AppButton
                            disabled={Object.keys(errors).length}
                            type={"submit"}
                            className="app-button--fill-3 home-cta__btn"
                          >
                            {t("actions.buyUahg")}
                          </AppButton>
                        </div>

                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="app-form__field-error"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
          <div className="home-cta__img">
            <WeldCTABg />
            <img src={data.image} alt="Rocket" />
          </div>
          {width <= 768 && (
            <div className="home-cta__actions">
              <div className="home-cta__actions">
                <Formik
                  enableReinitialize={true}
                  initialValues={INITIAL_DATA}
                  onSubmit={onSendHandler}
                  validationSchema={FormValidation}
                  innerRef={formikRef}
                >
                  {({ errors, touched }) => (
                    <Form className="app-form">
                      <div className="app-form__field app-form__field--input">
                        <label>{t("landing.inputSumLabel")}</label>
                        <div className="app-form__group">
                          <Field
                            type="text"
                            name="amount"
                            placeholder={"1 000 UAH"}
                            autoComplete="chrome-off"
                            className={
                              "home-cta__input" +
                              (errors.amount && touched.amount
                                ? " app-form__field--error"
                                : "")
                            }
                          />
                          <AppButton
                            disabled={Object.keys(errors).length}
                            type={"submit"}
                            className="app-button--fill-3 home-cta__btn"
                          >
                            {t("actions.buyUahg")}
                          </AppButton>
                        </div>

                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="app-form__field-error"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeCTA;
