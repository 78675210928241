// core
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// store
import { selectContact } from "../../../store/modules/contact/selectors";
import { sendContact } from "../../../store/modules/contact/services";

// components
import AppButton from "../../../components/atoms/Button";
import ChevronRight from "../../../components/icons/ChevronRight";

// styles
import "./index.scss";

// data
import { INITIAL_DATA } from "./data";

const HomeWelcome = ({ variables }) => {
  // store
  const dispatch = useDispatch();
  const contact = useSelector(selectContact);

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isTrySend, setIsTrySend] = useState(false);
  const formikRef = useRef();

  useEffect(() => {
    if (!contact.isLoading && isTrySend) {
      if (contact.isSuccess) {
        toast.success(t("modal.contactUs.success"));
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
      } else {
        toast.error(t("modal.contactUs.error"));
      }
    }
  }, [contact.isLoading]);

  const PHONE_NO_REGEX = /^[0-9\-\(\)\+ ]{8,16}$/;

  const ModalValidation = Yup.object().shape({
    phone: Yup.string()
      .matches(PHONE_NO_REGEX, {
        message: t("validation.isPhone"),
        excludeEmptyString: true,
      })
      .min(8, t("validation.min"))
      .max(16, t("validation.max"))
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.isEmail"))
      .required(t("validation.required")),
  });
  const onSendHandler = (payload) => {
    setIsTrySend(true);
    dispatch(sendContact(payload));
  };

  return (
    <div className="home-welcome" id="integration">
      {width > 768 && <div className="home-welcome__bg" />}

      <div className="app__wrapper">
        <div className="home-welcome__grid">
          <div
            className="home-welcome__box"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="home-welcome__form">
              <div className="home-welcome__form-title">
                {variables.invite_title.value}
              </div>
              <div className="home-welcome__form-text">
                {variables.invite_text.value}
              </div>

              <Formik
                enableReinitialize={true}
                initialValues={INITIAL_DATA}
                onSubmit={onSendHandler}
                validationSchema={ModalValidation}
                innerRef={formikRef}
              >
                {({ errors, touched }) => (
                  <Form className="app-form">
                    <div className="app-form__field app-form__field--input home-welcome__field">
                      <label> {t("modal.contactUs.inputPhoneLabel")}</label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder={t("modal.contactUs.inputPhonePlaceholder")}
                        autoComplete="chrome-off"
                        className={
                          errors.phone &&
                          touched.phone &&
                          "app-form__field--error"
                        }
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="app-form__field-error"
                      />
                    </div>
                    <div className="app-form__field app-form__field--input home-welcome__field">
                      <label>{t("modal.contactUs.inputEmailLabel")}</label>
                      <Field
                        type="text"
                        name="email"
                        placeholder={t("modal.contactUs.inputEmailPlaceholder")}
                        autoComplete="chrome-off"
                        className={
                          errors.email &&
                          touched.email &&
                          "app-form__field--error"
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="app-form__field-error"
                      />
                    </div>

                    <div className="home-welcome__actions">
                      <AppButton
                        disabled={
                          contact.isLoading || Object.keys(errors).length
                        }
                        type={"submit"}
                        loading={contact.isLoading}
                        size={"big"}
                        className="home-welcome__btn app-button--fill-3"
                        append={<ChevronRight />}
                      >
                        {t("actions.contacts")}
                      </AppButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWelcome;
