// core
import { useEffect, useRef } from "react";

// utils

// components

// styles
import "./index.scss";

const HomeServices = ({ generalData }) => {
  const scrollRef = useRef(null);
  const horizontalWheelScroll = (event) => {
    if (event.deltaY > 0) {
      scrollRef.current.scrollLeft += 100;
      event.preventDefault();
    } else {
      scrollRef.current.scrollLeft -= 100;
      event.preventDefault();
    }
  };

  useEffect(() => {
    let scrollEl = scrollRef?.current;
    if (scrollEl) {
      scrollEl.addEventListener("wheel", horizontalWheelScroll);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener("wheel", horizontalWheelScroll);
      }
    };
  }, []);

  return (
    <div className="home-services">
      <div className="app__wrapper">
        <div className="home-services__grid" ref={scrollRef}>
          {generalData.map((item) => (
            <a
              key={item.id}
              href={item.url}
              target="_blank"
              className="home-services__card"
            >
              <img src={item.image} alt={""} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
